<template>
  <svg viewBox="0 0 15 15" xmlns="http://www.w3.org/2000/svg" class="app-icon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8637 3.34467C13.1566 3.63756
      13.1566 4.11244 12.8637 4.40533L6.21791 11.0512C6.07726 11.1918 5.88649 11.2708
      5.68758 11.2708C5.48867 11.2708 5.2979 11.1918 5.15725 11.0512L2.13642 8.03033C1.84352
      7.73744 1.84352 7.26256 2.13642 6.96967C2.42931 6.67678 2.90418 6.67678 3.19708
      6.96967L5.68758 9.46017L11.8031 3.34467C12.096 3.05178 12.5709 3.05178 12.8637 3.34467Z"
    />
  </svg>
</template>
