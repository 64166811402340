<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { Category, Product } from '@/types/app';
import ApiRepository from '@/repositories/api';
import AppHeader from '@/components/AppHeader.vue';
import AppTabs from '@/components/AppTabs.vue';
import AppTab from '@/components/AppTab.vue';
import BottomBtn from '@/components/BottomBtn.vue';
import ProductsList from '@/components/ProductsList.vue';
import useProductsStore from '@/store/products';
import IconPercent from '@/icons/icon-percent.vue';
import AppBtn from '@/components/AppBtn.vue';
import { useRoute } from 'vue-router';

const products = ref<Product[] | undefined>();
const productsFiltered = ref<Product[]>([]);
const isLoadingProducts = ref(true);
const search = ref('');
const productsFilter = {
  special: false,
  category: null as Category|null,
  search: '',
};
const isShowedProduct = ref<boolean>(false);
const route = useRoute();

const filterProducts = () => {
  productsFiltered.value = products.value || [];
  if (productsFilter.category) {
    productsFiltered.value = productsFiltered.value
      .filter((e: Product) => e.categoriesIds.indexOf(productsFilter.category!.categoryId) !== -1);
  }
  if (productsFilter.special) {
    productsFiltered.value = productsFiltered.value.filter((e: Product) => e.special);
  }
  if (productsFilter.search) {
    productsFiltered.value = productsFiltered.value
      .filter((e: Product) => e.name.search(new RegExp(productsFilter.search, 'i')) !== -1);
  }
};

const showAllProducts = () => {
  productsFilter.special = false;
  productsFilter.category = null;
  filterProducts();
};

const showSpecialProducts = () => {
  productsFilter.special = true;
  productsFilter.category = null;
  filterProducts();
};

const showCategoryProducts = (category: Category) => {
  productsFilter.special = false;
  productsFilter.category = category;
  filterProducts();
};

const searchProducts = () => {
  productsFilter.search = search.value;
  filterProducts();
};

const productsStore = useProductsStore();

const loadProducts = async () => {
  isLoadingProducts.value = true;
  if (!productsStore.isProductsLoaded) {
    await productsStore.loadProducts();
  }
  products.value = productsStore.products;
  isLoadingProducts.value = false;
  showAllProducts();
};

const categories = ref<Category[]|undefined>();
const isLoadingCategories = ref(true);
const loadCategories = async () => {
  isLoadingCategories.value = true;
  const response = await ApiRepository.fetchCategories();
  categories.value = response.data;
  isLoadingCategories.value = false;
};

const onFilterChanged = (val: number) => {
  if (val === 0) {
    showAllProducts();
  } else if (val === 1) {
    showSpecialProducts();
  } else {
    const category: Category|undefined = (categories.value || [])[val - 2];
    if (category) showCategoryProducts(category);
  }
};

watch(search, searchProducts);
watch(route, (value, oldValue, onCleanup) => {
  isShowedProduct.value = value.name === 'product';
  if (isShowedProduct.value) {
    document.body.classList.add('no-scroll');
  } else {
    document.body.classList.remove('no-scroll');
  }
});

onMounted(() => {
  loadProducts();
  loadCategories();
});
</script>

<template>
  <div class="catalog-page">
    <div class="catalog-page__header">
      <app-header v-model:search.trim="search"/>
      <div class="container">
        <app-tabs @update:model-value="onFilterChanged" class="catalog-filter">
          <app-tab class="catalog-filter__tab">{{ $t('all') }}</app-tab>
          <app-tab class="catalog-filter__tab catalog-filter__tab--special" color="primary">
            <div class="catalog-filter__tab-special-label"><icon-percent/></div> {{ $t('stocks') }}
          </app-tab>
          <app-tab
            v-for="category in categories"
            :key="category.categoryId"
            class="catalog-filter__tab"
          >{{ category.name }}</app-tab>
        </app-tabs>
      </div>
    </div>
    <div class="container">
      <products-list
        :is-loading="isLoadingProducts"
        :products="productsFiltered"
      />
    </div>
    <div class="catalog-page__product">
      <router-view/>
    </div>
    <bottom-btn/>
  </div>
</template>

<style lang="scss">
.catalog-page {
  padding-top: 100px;
  &__header {
    background: var(--color-surface);
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
  &__product {
    .product-page {
      position: fixed;
      z-index: 3;
      left: 0;
      right: 0;
      top: 0;
      background: var(--color-surface);
      height: 100%;
      overflow-y: scroll;
      padding: 20px 0 200px;
    }
  }
}
.catalog-filter {
  &__tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &-special-label {
      background: currentColor;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 0.6em;
      padding: 0.7em;
      margin-right: 5px;
      svg {
        width: 1em;
        height: 1em;
        fill: #fff;
        line-height: 0;
      }
    }
    &--special {
      &:hover svg {
        fill: var(--color-primary);
      }
    }
  }
}
</style>
