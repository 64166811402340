<script setup lang="ts">
import { defineProps, PropType } from 'vue';
import { Product } from '@/types/app';
import helpers from '@/helpers';
import QuantityPanel from '@/components/QuantityPanel.vue';
import IconPercent from '@/icons/icon-percent.vue';
import useShoppingCartStore from '@/store/shopping-cart';

const shoppingCartStore = useShoppingCartStore();

defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
});
</script>

<template>
  <div class="product-card">
    <router-link
      class="product-card__wrapper"
      :to="{ name: 'product', params: { productId: product.productId } }"
    >
      <div class="product-card__image">
        <div class="product-card__special-label" v-if="product.special"><icon-percent/></div>
        <img :src="product.images[0]?.thumb" alt="item" class="item-img"/>
      </div>
      <div class="product-card__content">
        <div class="product-card__prices">
          <div class="product-card__price product-card__price--actual">
            {{ helpers.formatCurrency(product.special || product.price) }}
          </div>
          <div v-if="product.special"
               class="product-card__price product-card__price--old"
          >{{ helpers.formatCurrency(product.price) }}</div>
        </div>
        <div class="product-card__name">{{ product.name }}</div>
        <div v-if="product.weight" class="product-card__weight">
          {{ product.weight }}{{ product.weightUnitLabel }}
        </div>
      </div>
    </router-link>
    <div v-if="product.quantity === 0" class="product-card__not-in-stock">
      {{ $t('notInStock') }}
    </div>
    <quantity-panel
      v-else
      :model-value="shoppingCartStore.productQuantity(product.productId)"
      :max="product.quantity"
      @update:model-value="(e) => shoppingCartStore
        .changeProductQuantity([{ product, quantity: e }])"
    />
  </div>
</template>

<style scoped lang="scss">
.product-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  &__not-in-stock {
    text-align: center;
    color: var(--color-danger);
    padding: 0.34em 0;
  }
  &__image {
    position: relative;
    transition: opacity 0.3s;
    img {
      max-width: 100%;
      border-radius: 12px;
    }
  }
  &__wrapper {
    cursor: pointer;
    text-decoration: none;
    color: inherit;
  }
  &__wrapper:hover & {
    &__image {
      opacity: 0.65;
    }
    &__name {
      color: var(--color-primary);
    }
  }
  &__special-label {
    background: #fff;
    border-radius: 50%;
    padding: 0.5em;
    position: absolute;
    font-size: 12px;
    top: 4px;
    left: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    svg {
      width: 1em;
      height: 1em;
      fill: var(--color-primary);
      line-height: 0;
    }
  }
  &__prices {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  &__price {
    &--actual {
      font-family: Bastion, sans-serif;
      font-size: 16px;
    }
    &--old {
      color: var(--color-primary);
      text-decoration: line-through;
      margin-left: 8px;
    }
  }
  &__weight {
    color: var(--color-dark-grey);
  }
  &__content {
    padding: 0 8px;
    margin: 5px 0;
  }
}
</style>
