<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="app-icon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976
      5.31658 15.7071 5.70711L5.70711 15.7071C5.31658 16.0976 4.68342 16.0976 4.29289
      15.7071C3.90237 15.3166 3.90237 14.6834 4.29289 14.2929L14.2929 4.29289C14.6834
      3.90237 15.3166 3.90237 15.7071 4.29289Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.29289 4.29289C4.68342 3.90237 5.31658
    3.90237 5.70711 4.29289L15.7071 14.2929C16.0976 14.6834 16.0976 15.3166 15.7071
    15.7071C15.3166 16.0976 14.6834 16.0976 14.2929 15.7071L4.29289 5.70711C3.90237 5.31658
    3.90237 4.68342 4.29289 4.29289Z"/>
  </svg>
</template>
