<template>
  <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.77344 3.08594C4.77344 4.01302 4.54427 4.71615 4.08594 5.19531C3.63281
    5.66927 3.06771 5.90625 2.39062 5.90625C2.06771 5.90625 1.76562 5.85156 1.48438
    5.74219C1.20833 5.63281 0.955729 5.46875 0.726562 5.25C0.502604 5.03125 0.325521
    4.73698 0.195312 4.36719C0.0651042 3.9974 0 3.57031 0 3.08594C0 2.16406 0.229167
    1.46615 0.6875 0.992188C1.14583 0.513021 1.71354 0.273438 2.39062 0.273438C3.06771
    0.273438 3.63281 0.513021 4.08594 0.992188C4.54427 1.46615 4.77344 2.16406
    4.77344 3.08594ZM2.39062 4.70312C2.5625 4.70312 2.70052 4.66667 2.80469
    4.59375C2.91406 4.51562 2.99479 4.39583 3.04688 4.23438C3.09896 4.07292 3.13281
    3.90885 3.14844 3.74219C3.16406 3.57031 3.17188 3.35156 3.17188 3.08594C3.17188
    2.85677 3.16406 2.66406 3.14844 2.50781C3.13802 2.35156 3.10677 2.1875 3.05469
    2.01562C3.00781 1.83854 2.92708 1.70573 2.8125 1.61719C2.70312 1.52344 2.5625
    1.47656 2.39062 1.47656C2.21875 1.47656 2.07552 1.52344 1.96094 1.61719C1.84635
    1.70573 1.76562 1.83854 1.71875 2.01562C1.67188 2.1875 1.64062 2.35156 1.625
    2.50781C1.60938 2.66406 1.60156 2.85677 1.60156 3.08594C1.60156 3.29427
    1.60677 3.47135 1.61719 3.61719C1.6276 3.76302 1.64844 3.90885 1.67969
    4.05469C1.71094 4.20052 1.7526 4.31771 1.80469 4.40625C1.86198 4.49479 1.9401
    4.56771 2.03906 4.625C2.13802 4.67708 2.25521 4.70312 2.39062 4.70312ZM11.6953
    8.41406C11.6953 9.34115 11.4661 10.0443 11.0078 10.5234C10.5547 10.9974 9.98958
    11.2344 9.3125 11.2344C8.98958 11.2344 8.6875 11.1797 8.40625 11.0703C8.13021
    10.9609 7.88021 10.7969 7.65625 10.5781C7.43229 10.3594 7.25521 10.0651 7.125
    9.69531C6.99479 9.32552 6.92969 8.89844 6.92969 8.41406C6.92969 7.49219 7.15625
    6.79427 7.60938 6.32031C8.06771 5.84115 8.63542 5.60156 9.3125 5.60156C9.98958
    5.60156 10.5547 5.84115 11.0078 6.32031C11.4661 6.79427 11.6953 7.49219 11.6953
    8.41406ZM9.3125 10.0312C9.44792 10.0312 9.5651 10.0052 9.66406 9.95312C9.76302
    9.89583 9.83854 9.82292 9.89062 9.73438C9.94792 9.64583 9.99219 9.52865 10.0234
    9.38281C10.0547 9.23698 10.0755 9.09115 10.0859 8.94531C10.0964 8.79948 10.1016
    8.6224 10.1016 8.41406C10.1016 8.1849 10.0938 7.99219 10.0781 7.83594C10.0625
    7.67969 10.0312 7.51562 9.98438 7.34375C9.9375 7.16667 9.85677 7.03385 9.74219
    6.94531C9.6276 6.85156 9.48438 6.80469 9.3125 6.80469C9.14062 6.80469 8.9974
    6.85156 8.88281 6.94531C8.77344 7.03385 8.69271 7.16667 8.64062 7.34375C8.59375
    7.51562 8.5625 7.67969 8.54688 7.83594C8.53646 7.99219 8.53125 8.1849 8.53125
    8.41406C8.53125 8.67969 8.53906 8.89844 8.55469 9.07031C8.57031 9.23698 8.60417
    9.40104 8.65625 9.5625C8.70833 9.72396 8.78646 9.84375 8.89062 9.92188C9 9.99479
    9.14062 10.0312 9.3125 10.0312ZM8.57812 0.414062L4.80469 10.9609C4.78385 11.0182
    4.76302 11.0625 4.74219 11.0938C4.72656 11.125 4.6901 11.1562 4.63281
    11.1875C4.58073 11.2188 4.51562 11.2344 4.4375 11.2344H3.61719C3.39323 11.2344
    3.28125 11.1745 3.28125 11.0547C3.28125 11.0443 3.29688 10.9922 3.32812
    10.8984L7.14062 0.257812C7.1875 0.0859375 7.3099 0 7.50781 0H8.32031C8.52344
    0 8.625 0.0651042 8.625 0.195312C8.625 0.263021 8.60938 0.335938 8.57812 0.414062Z"/>
  </svg>
</template>
