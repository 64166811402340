import { defineStore } from 'pinia';
import { PaymentMethod } from '@/types/app';
import ApiRepository from '@/repositories/api';

const usePaymentMethodsStore = defineStore({
  id: 'payment-methods',
  state: () => ({
    methods: [] as PaymentMethod[],
    isLoading: false,
    isLoaded: false,
  }),
  actions: {
    async loadMethods() {
      this.isLoaded = false;
      this.isLoading = true;
      const response = await ApiRepository.fetchPaymentMethods();
      this.methods = response.data;
      this.isLoading = false;
      this.isLoaded = true;
    },
  },
  getters: {
    //
  },
});

export default usePaymentMethodsStore;
