<template>
  <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.38477 6.56836V5.7959C9.38477 5.69108 9.37109 5.62728 9.34375
      5.60449C9.31641 5.57715 9.25033 5.56348 9.14551 5.56348H3.23926C3.13444
      5.56348 3.06836 5.57715 3.04102 5.60449C3.01367 5.62728 3 5.69108 3
      5.7959V6.56836C3 6.67318 3.01367 6.73926 3.04102 6.7666C3.06836 6.79395
      3.13444 6.80762 3.23926 6.80762H9.14551C9.25033 6.80762 9.31641 6.79395
      9.34375 6.7666C9.37109 6.7347 9.38477 6.66862 9.38477 6.56836Z"
    />
  </svg>
</template>
