<script setup lang="ts">
import IconCheck from '@/icons/icon-check.vue';
import IconArrowRight from '@/icons/icon-arrow-right.vue';
import { defineProps, PropType } from 'vue';
import { Order } from '@/types/app';
import moment from 'moment';
import IconTime from '@/icons/icon-time.vue';
import IconCross from '@/icons/icon-cross.vue';
import helpers from '../helpers';

const props = defineProps({
  order: {
    type: Object as PropType<Order>,
    required: true,
  },
});

let orderStatus: 'success' | 'waiting' | 'error' = 'error';
if (props.order.orderStatusId === 5) {
  orderStatus = 'success';
} else if (props.order.orderStatusId === 1 || props.order.orderStatusId === 2) {
  orderStatus = 'waiting';
}
</script>

<template>
  <router-link
    :to="{ name: 'order', params: { orderId: order.orderId } }"
    :class="[
      'order-card',
      `order-card--${orderStatus}`,
    ]"
  >
    <div class="order-card__left-content">
      <div class="order-card__status">
        <icon-check v-if="orderStatus === 'success'"/>
        <icon-time v-else-if="orderStatus === 'waiting'"/>
        <icon-cross v-else/>
      </div>
      <div class="order-card__info">
        <div class="order-card__id">№{{ order.orderId }}</div>
        <div class="order-card__date">{{ moment(order.dateAdded).format('DD.MM.YYYY HH:mm') }}</div>
      </div>
    </div>
    <div class="order-card__right-content">
      <div class="order-card__total-sum">{{ helpers.formatCurrency(order.total) }}</div>
      <div class="order-card__btn"><icon-arrow-right/></div>
    </div>
  </router-link>
</template>

<style lang="scss">
.order-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--color-surface);
  transition: background-color 0.3s;
  border-radius: 12px;
  padding: 4px;
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  @at-root {
    .theme-light {
      .order-card:hover {
        background: var(--color-light-grey);
      }
    }
    .theme-dark {
      .order-card:hover {
        background: var(--color-dark-grey);
      }
    }
  }
  &__left-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  &__right-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  &__status {
    border-radius: 50%;
    font-size: 1.1em;
    padding: 0.5em;
    line-height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    .app-icon {
      fill: currentColor;
      width: 1em;
      height: 1em;
    }
  }
  &__id {
    margin-bottom: 4px;
  }
  &__date {
    color: var(--color-grey);
  }
  &__total-sum {
    color: var(--color-grey);
    margin-right: 8px;
  }
  &__btn {
    font-size: 1.75em;
    line-height: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .app-icon {
      fill: var(--color-text);
      width: 1em;
      height: 1em;
    }
  }
  &--success & {
    &__status {
      background: var(--color-success-light);
      color: var(--color-success);
    }
  }
  &--waiting & {
    &__status {
      background: var(--color-warning-light);
      color: var(--color-warning);
    }
  }
  &--error & {
    &__status {
      background: var(--color-danger-light);
      color: var(--color-danger);
    }
  }
}
</style>
