<script setup lang="ts">
import {
  inject,
  onBeforeMount,
  ref,
  computed,
  ComputedRef,
  defineProps, PropType,
} from 'vue';

const onTabClick = inject<(index: number) => void>('onTabClick');
const currentActiveTabIndex = inject<ComputedRef<number>>('currentActiveTabIndex');
const onTabBeforeMount = inject<() => number>('onTabBeforeMount', () => 0);
const currentTabIndex = ref<number>(0);

const isActive = computed(() => currentActiveTabIndex?.value === currentTabIndex.value);

defineProps({
  color: {
    type: String as PropType<'black' | 'primary'>,
    default: 'black',
  },
});

onBeforeMount(() => {
  currentTabIndex.value = onTabBeforeMount();
});
</script>

<template>
  <button
    type="button"
    :class="[
      'app-tab',
      isActive ? 'app-tab--active' : '',
      `app-tab--${color}`,
    ]"
    @click.prevent="() => onTabClick(currentTabIndex)"
  ><slot/></button>
</template>

<style lang="scss">
.app-tab {
  font-size: inherit;
  padding: 0.3em 0.6em;
  color: var(--color-text);
  border-radius: 0.6em;
  background: transparent;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  border: none;
  font-weight: 500;
  &--primary {
    color: var(--color-primary);
  }
  &--active, &:hover {
    background-color: var(--color-primary);
    color: #fff;
  }
  & + & {
    margin-left: 6px;
  }
}
</style>
