import { App, inject } from 'vue';
import router from '@/router';
// eslint-disable-next-line import/extensions,import/no-unresolved
import { Locale } from '@/types/app';
// eslint-disable-next-line import/extensions,import/no-unresolved
import Telegram from '@/services/telegram-web-app/declare';

class TelegramWebApp {
  protected readonly WebApp = window.Telegram.WebApp;

  constructor() {
    this.WebApp.BackButton.onClick(() => {
      router.back();
    });
  }

  // eslint-disable-next-line class-methods-use-this
  protected get primaryColor(): string {
    return getComputedStyle(document.documentElement).getPropertyValue('--color-primary');
  }

  public onThemeChanged(handler: () => void) {
    this.WebApp.onEvent('themeChanged', handler);
  }

  public expand() {
    this.WebApp.expand();
  }

  public close() {
    this.WebApp.close();
  }

  public getInitData(): string {
    return this.WebApp.initData;
  }

  public showBackBtn(): void {
    this.WebApp.BackButton.show();
  }

  public hideBackBtn(): void {
    this.WebApp.BackButton.hide();
  }

  public showMainBtnProgress() {
    this.WebApp.MainButton.showProgress();
  }

  public hideMainBtnProgress() {
    this.WebApp.MainButton.hideProgress();
  }

  public showAlert(message: string) {
    this.WebApp.showAlert(message);
  }

  public showMainBtn(text: string, onClick: () => void) {
    this.WebApp.MainButton.text = text;
    this.WebApp.MainButton.color = this.primaryColor;
    this.WebApp.MainButton.show();
    this.WebApp.MainButton.onClick(onClick);
  }

  public removeMainBtnHandler(handler: () => any) {
    this.WebApp.MainButton.offClick(handler);
  }

  public hideMainBtn() {
    this.WebApp.MainButton.hide();
  }

  public isMainBtnAvailable(): boolean {
    return this.WebApp.isVersionAtLeast('6.10');
  }

  public openInvoice(url: string, callback: Telegram.WebApp.InvoiceClosedEventHandler): void {
    this.WebApp.openInvoice(url, callback);
  }

  public get colorScheme(): Telegram.WebApp.ColorScheme {
    return this.WebApp.colorScheme;
  }

  public get languageCode(): Locale|undefined {
    switch (this.WebApp.initDataUnsafe.user?.language_code) {
      case 'ru': return 'ru-ru';
      case 'uz': return 'uz-uz';
      default: return undefined;
    }
  }

  public set headerColor(color: string) {
    this.WebApp.setHeaderColor(color);
  }

  public set backgroundColor(color: string) {
    this.WebApp.setBackgroundColor(color);
  }
}

// const telegramWebApp = new TelegramWebApp();
const telegramWebAppKey = Symbol('telegram web app');

const createTelegramWebApp = () => ({
  install(app: App): void {
    app.provide(telegramWebAppKey, new TelegramWebApp());
  },
});

const useTelegramWebApp = (): TelegramWebApp => inject(telegramWebAppKey) as TelegramWebApp;

export {
  createTelegramWebApp,
  useTelegramWebApp,
};

// function useTelegramWebApp(): TelegramWebApp {
//   return inject(telegramWebAppKey) as TelegramWebApp;
// }

// export default telegramWebApp;
