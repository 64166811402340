import { defineStore } from 'pinia';
import { Coupon, Order } from '@/types/app';

const useCheckoutStore = defineStore({
  id: 'checkout',
  state: () => ({
    order: undefined as undefined|Order,
    coupon: undefined as undefined|Coupon,
  }),
  actions: {
    changeOrder(order: Order) {
      this.order = order;
    },
    changeCoupon(coupon: Coupon) {
      this.coupon = coupon;
    },
    reset() {
      this.order = undefined;
      this.coupon = undefined;
    },
  },
  getters: {
    //
  },
});

export default useCheckoutStore;
