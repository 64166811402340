<script setup lang="ts">
import Inputmask from 'inputmask';
import {
  defineProps,
  defineEmits,
  onMounted,
  PropType,
  ref,
  watch,
  computed,
} from 'vue';

const inputEl = ref();
let maskInput: Inputmask.Instance | null = null;

const props = defineProps({
  type: {
    type: String as PropType<'text' | 'number'>,
    required: false,
    default: 'text',
  },
  label: {
    type: String,
  },
  placeholder: {
    type: String,
  },
  mask: {
    type: Object as PropType<Inputmask.Instance>,
    required: false,
    default: null,
  },
  modelValue: {
    type: [String, Number] as PropType<string|number>,
    default: '',
  },
  autoUnmask: {
    type: Boolean,
    default: true,
  },
});

const emits = defineEmits([
  'update:modelValue',
]);

const localValue = ref<any>(props.modelValue);

const unmaskedValue = computed(() => {
  let { value } = localValue;
  if (maskInput != null && props.autoUnmask) {
    maskInput.setValue(value);
    value = maskInput.unmaskedvalue();
  }
  if (props.type === 'number') {
    value = parseFloat(value);
    if (Number.isNaN(value)) {
      value = 0;
    }
  }
  return value;
});

watch(localValue, () => emits('update:modelValue', unmaskedValue.value));
watch(() => props.modelValue, (val: any) => {
  localValue.value = val;
});

onMounted(() => {
  if (props.mask) {
    maskInput = props.mask.mask(inputEl.value);
  }
});
</script>

<template>
  <label class="app-text-field">
    <span v-if="label" class="app-text-field__label">{{ label }}</span>
    <input
      v-model="localValue"
      type="text"
      class="app-text-field__input"
      :placeholder="placeholder"
      ref="inputEl"
    />
  </label>
</template>

<style lang="scss">
.app-text-field {
  display: flex;
  flex-direction: column;
  &__label {
    margin-bottom: 4px;
  }
  &__input {
    appearance: none;
    background: var(--color-field-background);
    border: solid var(--color-field-border) 1px;
    color: var(--color-text);
    font-family: inherit;
    font-size: inherit;
    padding: 0.9em 1.2em;
    border-radius: 16px;
    transition: border-color 0.3s;
    &:focus {
      border-color: var(--color-grey);
    }
  }
}
.app-text-field__input::placeholder {
  color: var(--color-grey);
  opacity: 1;
}
.app-text-field__input::-ms-input-placeholder {
  color: var(--color-grey);
}
</style>
