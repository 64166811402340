<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.5 3.83331H5C5.27614 3.83331 5.5 3.60946 5.5
    3.33331C5.5 3.05717 5.27614 2.83331 5 2.83331H2.4C1.90294 2.83331 1.5 3.23626
    1.5 3.73331V13.6C1.5 14.0971 1.90296 14.5 2.4 14.5H13.6C14.0971 14.5 14.5 14.0971 14.5
    13.6V3.73331C14.5 3.23627 14.0971 2.83331 13.6 2.83331H11C10.7239 2.83331 10.5 3.05717
    10.5 3.33331C10.5 3.60946 10.7239 3.83331 11 3.83331H13.5V13.5H2.5V3.83331Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.65454 2.36525C4.80188 1.48124 5.56672
    0.833313 6.46293 0.833313H9.53723C10.4334 0.833313 11.1983 1.48121 11.3456 2.36522C11.3456
    2.36523 11.3456 2.3652 11.3456 2.36522L11.4824 3.18532C11.4985 3.28288 11.4986 3.38309 11.4825
    3.48065L11.3456 4.30137C11.3456 4.30138 11.3456 4.30135 11.3456 4.30137C11.1983 5.18537 10.4334
    5.83331 9.53723 5.83331H6.46293C5.56672 5.83331 4.80188 5.18538 4.65454 4.30138L4.51786
    3.48127C4.50154 3.38333 4.50153 3.28333 4.51785 3.18539L4.65454 2.36525ZM6.46293
    1.83331C6.05557 1.83331 5.70791 2.12782 5.64094 2.52965L5.50699 3.33331L5.64094 4.13698C5.70791
    4.5388 6.05557 4.83331 6.46293 4.83331H9.53723C9.94462 4.83331 10.2923 4.53879 10.3592
    4.13699L10.4932 3.33331L10.3592 2.52967C10.2923 2.12788 9.94462 1.83331 9.53723
    1.83331H6.46293Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.05491 2.97028C7.4395 2.81645 7.87597
    3.00351 8.02981 3.3881L8.69648 5.05477C8.85031 5.43935 8.66325 5.87583 8.27866 6.02967C7.89407
    6.1835 7.4576 5.99644 7.30376 5.61185L6.63709 3.94519C6.48326 3.5606 6.67032 3.12412
    7.05491 2.97028Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.16675 11.3333C4.16675 11.0572
    4.39061 10.8333 4.66675 10.8333H11.3334C11.6096 10.8333 11.8334 11.0572 11.8334
    11.3333C11.8334 11.6095 11.6096 11.8333 11.3334 11.8333H4.66675C4.39061 11.8333
    4.16675 11.6095 4.16675 11.3333Z"/>
  </svg>
</template>
