<script setup lang="ts">
// eslint-disable-next-line import/no-cycle
import { useTelegramWebApp } from '@/services/telegram-web-app';
import useShoppingCartStore from '@/store/shopping-cart';
import AppHeader from '@/components/AppHeader.vue';
import CartCard from '@/components/CartCard.vue';
import { onMounted, watch } from 'vue';
import router from '@/router';
import AppLoader from '@/components/AppLoader.vue';
import useShippingMethodsStore from '@/store/shipping-methods';
import usePaymentMethodsStore from '@/store/payment-methods';
import { useI18n } from 'vue-i18n';
import { onUnmounted } from '@vue/runtime-core';
import useProductsStore from '@/store/products';
import helpers from '@/helpers';
import useAppConfigStore from '@/store/app-config';

const shoppingCartStore = useShoppingCartStore();
const productsStore = useProductsStore();
const telegramWebApp = useTelegramWebApp();
const shippingMethodsStore = useShippingMethodsStore();
const paymentMethodsStore = usePaymentMethodsStore();
const i18n = useI18n();
const appConfigStore = useAppConfigStore();

const onMainBtnClick = () => {
  // eslint-disable-next-line guard-for-in,no-restricted-syntax
  for (const productKey in shoppingCartStore.products) {
    const cartProduct = shoppingCartStore.products[productKey];
    const productQuantity = productsStore.getProductQuantity(cartProduct.product.productId);
    if (cartProduct.quantity > productQuantity) {
      let msg = `${i18n.t('errorOrderNotInStock')}\n\n`;
      msg += i18n.t('errorOrderNotInStockProduct', {
        productName: cartProduct.product.name,
        cartQuantity: cartProduct.quantity,
        productQuantity,
      });
      telegramWebApp.showAlert(msg);
      return;
    }
  }
  router.push({ name: 'checkout' });
};

onMounted(() => {
  telegramWebApp.showMainBtn(i18n.t('checkoutOrder'), onMainBtnClick);
  if (!productsStore.isProductsLoaded && !productsStore.isLoadingProducts) {
    productsStore.loadProducts();
  }
  if (shoppingCartStore.totalCost < appConfigStore.config.min_order_amount) {telegramWebApp.hideMainBtn();}
  shippingMethodsStore.loadMethods();
  paymentMethodsStore.loadMethods();
});

onUnmounted(() => {
  telegramWebApp.removeMainBtnHandler(onMainBtnClick);
});

watch(() => shoppingCartStore.totalQuantity, () => {
  if (shoppingCartStore.totalQuantity === 0) telegramWebApp.hideMainBtn();
  if (shoppingCartStore.totalCost < appConfigStore.config.min_order_amount) {telegramWebApp.hideMainBtn();} 
  else telegramWebApp.showMainBtn(i18n.t('checkoutOrder'), onMainBtnClick);
});

console.log(shoppingCartStore);
</script>

<template>
  <div class="cart-page">
    <app-header :title="$t('yourOrder')" :with-search="false"/>
    <div class="cart-page__content">
      <div class="container">
        <div v-if="shoppingCartStore.isLoadingCart || productsStore.isLoadingProducts"
         class="cart-page__loader"
        >
          <app-loader/>
        </div>
        <div v-else-if="shoppingCartStore.totalQuantity === 0"
             class="cart-page__empty">{{ $t('cartIsEmpty') }}</div>
        <div v-else class="cart-items-list">
          <cart-card
            v-for="cartProduct in shoppingCartStore.products"
            :key="cartProduct.product.productId"
            :cart-product="cartProduct"
          />
          <br><hr>
          <div class="heading heading--3 checkout-confirm-page__section-title">
            {{ $t('total') }}&nbsp;       
            {{ helpers.formatCurrency(shoppingCartStore.totalCost) }}
          </div>
          <div v-if="shoppingCartStore.totalCost < appConfigStore.config.min_order_amount"
             class="">{{ $t('errorMinOrderAmount', {
              min_order_amount: helpers.formatCurrency( appConfigStore.config.min_order_amount ),
              }) }}
          </div>
          <app-alert class="checkout-confirm-page__alert">
            {{ $t('priceCanBeChange') }}
            <template #icon><icon-warning/></template>
          </app-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cart-page {
  &__empty {
    color: var(--color-dark-grey);
    text-align: center;
  }
  &__content {
    padding: 20px 0;
  }
}
.cart-items-list {
  .cart-card {
    & + .cart-card {
      margin-top: 16px;
    }
  }
}
</style>
