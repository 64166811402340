import Inputmask from 'inputmask';

export const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
export const tokenCookieKey = 'c_token';

export const phoneMaskOptions: Inputmask.Options = {
  mask: '+\\9\\98 (99) 999 99 99',
  placeholder: '_',
  inputmode: 'numeric',
  onUnMask(maskedValue: string, unmaskedValue: string) {
    return unmaskedValue.length > 0 ? `+998${unmaskedValue}` : '';
  },
};
export const phoneMask: Inputmask.Instance = new Inputmask(phoneMaskOptions);
export const maskPhoneString = function (phone: string): string {
  return Inputmask.format(phone, phoneMaskOptions);
};

export default {
  apiBaseUrl,
  tokenCookieKey,
};
