import { defineStore } from 'pinia';
import { Product } from '@/types/app';
import ApiRepository from '@/repositories/api';

const useProductsStore = defineStore({
  id: 'products',
  state: () => ({
    products: [] as Product[],
    isLoadingProducts: false,
    isProductsLoaded: false,
  }),
  actions: {
    async loadProducts() {
      this.isProductsLoaded = false;
      this.isLoadingProducts = true;
      const response = await ApiRepository.fetchProducts();
      this.products = response.data;
      this.isLoadingProducts = false;
      this.isProductsLoaded = true;
    },
  },
  getters: {
    getProduct(state): (productId: number) => undefined|Product {
      return (productId: number) => state.products
        .find((e: Product) => e.productId === productId);
    },
    getProductQuantity(state): (productId: number) => number {
      return (productId: number) => this.getProduct(productId)?.quantity || 0;
    },
  },
});

export default useProductsStore;
