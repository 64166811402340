import axios, {
  AxiosError, AxiosInstance,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import Cookie from 'js-cookie';
import {
  apiBaseUrl,
  tokenCookieKey,
} from '@/app.config';
import {
  DataResponse,
  ResponseError,
  ResponseErrorTypes,
  ResponseStatus,
} from '@/types/api/common';
import {
  AppConfig,
  Category,
  Coupon,
  Locale,
  Order, OrderCreateStockErrorDataItem,
  OrderProduct,
  OrderTotal,
  PaymentMethod,
  Product,
  ProductImage,
  Profile,
  ProfileAddress,
  ShippingCartProduct,
  ShippingMethod,
} from '@/types/app';

function requestResolve(config: AxiosRequestConfig<any>) {
  const token = Cookie.get(tokenCookieKey);
  if (token && config.headers) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

function requestReject(err: unknown) {
  return Promise.reject(err);
}

function responseResolve(response: AxiosResponse) {
  return response;
}

function responseReject(err: AxiosError) {
  if (err.response) {
    if (err.request.responseURL.indexOf(`${apiBaseUrl}/auth/login`) !== 0
      && !window.location.href.includes('/auth/login')
      && err.response.status === ResponseStatus.UNAUTHORIZED) {
      Cookie.remove(tokenCookieKey);
      window.location.href = '/auth/login';
    }
  }

  return Promise.reject(err);
}

axios.defaults.baseURL = apiBaseUrl;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
axios.interceptors.request.use(requestResolve, requestReject);
axios.interceptors.response.use(responseResolve, responseReject);

class ApiRepository {
  private static authToken: string;

  private static sessionId: string|undefined = undefined;

  public static locale: Locale;

  private static axios: AxiosInstance = axios.create();

  protected static requestGet(resource: string, needAuth = false): AxiosPromise<any> {
    const headers: Record<string, string> = {};
    if (needAuth) {
      headers.Authorization = `Bearer ${this.authToken}`;
    }
    headers['X-Language'] = this.locale;

    return this.axios.get(`${apiBaseUrl}${resource}`, {
      headers,
    });
  }

  protected static requestPostForm(
    resource: string,
    data?: object,
    needAuth = false,
  ): AxiosPromise<any> {
    const headers: Record<string, string> = {};
    if (needAuth) {
      headers.Authorization = `Bearer ${this.authToken}`;
    }
    headers['X-Language'] = this.locale;

    return this.axios.postForm(`${apiBaseUrl}${resource}`, data, {
      headers,
    });
  }

  protected static requestPost(
    resource: string,
    data?: object,
    needAuth = false,
  ): AxiosPromise<any> {
    const headers: Record<string, string> = {};
    if (needAuth) {
      headers.Authorization = `Bearer ${this.authToken}`;
    }
    headers['X-Language'] = this.locale;

    return this.axios.post(`${apiBaseUrl}${resource}`, data, {
      headers,
    });
  }

  public static async fetchCategories(): Promise<DataResponse<Category[]>> {
    const response = await this.requestGet('category');
    return {
      data: response.data.data.map((e: any): Category => ({
        categoryId: e.category_id,
        name: e.name,
        description: e.description,
        parentId: e.parent_id,
        sortOrder: e.sort_order,
      })),
    };
  }

  public static async fetchProducts(): Promise<DataResponse<Product[]>> {
    const response = await this.requestGet('product');

    return {
      data: response.data.data.map((e: any) => this.mapProduct(e)),
    };
  }

  public static async twaAuth(initData: string): Promise<void> {
    const response = await this.requestPostForm('twa.auth', {
      init_data: initData,
    });

    this.authToken = response.data.token;
    this.sessionId = response.data.session_id;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.axios.defaults.headers.common['X-Session-Id'] = this.sessionId;
  }

  public static async fetchTwaConfig(): Promise<DataResponse<AppConfig>> {
    const response = await this.requestGet('twa.config');

    return {
      data: {
        store: response.data.data.store,
        min_order_amount: response.data.data.min_order_amount,
      },
    };
  }

  public static async fetchProfile(): Promise<DataResponse<Profile>> {
    const response = await this.requestGet('profile', true);

    return {
      data: this.mapProfile(response.data.data),
    };
  }

  public static async fetchShippingMethods(): Promise<DataResponse<ShippingMethod[]>> {
    const response = await this.requestGet('shipping_method', true);

    return {
      data: response.data.data.map((e: any) => this.mapShippingMethod(e)),
    };
  }

  public static async fetchPaymentMethods(): Promise<DataResponse<PaymentMethod[]>> {
    const response = await this.requestGet('payment_method', true);

    return {
      data: response.data.data.map((e: any) => this.mapPaymentMethod(e)),
    };
  }

  public static async fetchCart(): Promise<DataResponse<ShippingCartProduct[]>> {
    const response = await this.requestGet('cart', true);

    return {
      data: response.data.data.map((e: any) => this.mapCartProduct(e)),
    };
  }

  public static async clearCart(): Promise<DataResponse<ShippingCartProduct[]>> {
    const response = await this.requestPost('cart.clear', undefined, true);

    return {
      data: [],
    };
  }

  public static async changeCartProduct(items: { productId: number, quantity: number }[])
    : Promise<DataResponse<ShippingCartProduct[]>> {
    const response = await this
      .requestPost('cart.change_quantity', items.map((e) => ({
        product_id: e.productId,
        quantity: e.quantity,
      })), true);

    return {
      data: response.data.data.map((e: any) => this.mapCartProduct(e)),
    };
  }

  public static async checkCoupon(coupon: string): Promise<DataResponse<Coupon>|null> {
    try {
      const response = await this
        .requestPost('coupon.check', { coupon }, true);

      return {
        data: {
          couponId: response.data.data.coupon_id,
          name: response.data.data.name,
          code: response.data.data.code,
          type: response.data.data.type,
          discount: response.data.data.discount,
          shipping: response.data.data.shipping,
          total: response.data.data.total,
        },
      };
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  public static async payOrderViaTelegram(orderId: number)
    : Promise<DataResponse<{ url: string }|null>> {
    const response = await this
      .requestPost('twa.pay_order', {
        order_id: orderId,
      }, true);

    if (response.status === 204) {
      return {
        data: null,
      };
    }

    return {
      data: {
        url: response.data.url,
      },
    };
  }

  public static async fetchOrders(): Promise<DataResponse<Order[]>> {
    const response = await this
      .requestGet('order', true);

    return {
      data: response.data.data.map((e: any) => this.mapOrder(e)),
    };
  }

  public static async createOrder(data: {
    recipient: {
      name: string,
      phone: string,
    },
    shippingMethod: string,
    paymentMethod: string,
    address: string|undefined,
    coupon?: string,
  }): Promise<DataResponse<Order>|ResponseError<OrderCreateStockErrorDataItem[]>> {
    const reqData: Record<string, any> = {
      recipient: {
        name: data.recipient.name,
        phone: data.recipient.phone,
      },
      shipping_method: data.shippingMethod,
      payment_method: data.paymentMethod,
      address: data.address,
    };
    if (data.coupon) {
      reqData.coupon = data.coupon;
    }
    try {
      const response = await this
        .requestPost('order.create', reqData, true);
      return {
        data: this.mapOrder(response.data.data),
      };
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const { response } = e;
      let errorData;
      if (response.data.error === ResponseErrorTypes.SOME_PRODUCT_NOT_IN_STOCK) {
        errorData = response.data.data.map((e: any) => ({
          productId: e.product_id,
          name: e.name,
          cartQuantity: e.cart_quantity,
          productQuantity: e.product_quantity,
        } as OrderCreateStockErrorDataItem));
      }
      return {
        error: response.data.error,
        data: errorData,
      };
    }
  }

  protected static mapOrder(orderData: any): Order {
    return {
      orderId: orderData.order_id,
      comment: orderData.comment,
      dateAdded: new Date(orderData.date_added),
      firstname: orderData.firstname,
      lastname: orderData.lastname,
      orderStatus: orderData.order_status,
      orderStatusId: orderData.order_status_id,
      paymentMethod: orderData.payment_method,
      shippingAddress: orderData.shipping_address,
      shippingMethod: orderData.shipping_method,
      shippingMethodCode: orderData.shipping_method_code,
      total: orderData.total,
      telephone: orderData.telephone,
      totals: orderData.totals.map((e: any): OrderTotal => ({
        code: e.code,
        sortOrder: e.sort_order,
        title: e.title,
        value: e.value,
      })),
      products: orderData.products.map((e: any): OrderProduct => ({
        orderProductId: e.order_product_id,
        productId: e.product_id,
        price: e.price,
        name: e.name,
        quantity: e.quantity,
        total: e.total,
      })),
    };
  }

  protected static mapCartProduct(cartProductData: any): ShippingCartProduct {
    return {
      quantity: cartProductData.quantity,
      product: this.mapProduct(cartProductData.product),
    };
  }

  protected static mapPaymentMethod(paymentMethodData: any): PaymentMethod {
    return {
      name: paymentMethodData.name,
      code: paymentMethodData.code,
      sortOrder: paymentMethodData.sort_order,
    };
  }

  protected static mapShippingMethod(shippingMethodData: any): ShippingMethod {
    return {
      name: shippingMethodData.name,
      code: shippingMethodData.code,
      sortOrder: shippingMethodData.sort_order,
      cost: shippingMethodData.cost,
      //needAddress: shippingMethodData.need_address,
      needAddress: true,
    };
  }

  protected static mapProfile(profileData: any): Profile {
    return {
      customerId: profileData.customer_id,
      firstname: profileData.firstname,
      lastname: profileData.lastname,
      telephone: profileData.telephone,
      addresses: profileData.addresses.map((e: any): ProfileAddress => ({
        addressId: e.address_id,
        address: e.address,
        default: e.default,
      })),
    };
  }

  protected static mapProduct(productData: any): Product {
    return {
      productId: productData.product_id,
      categoriesIds: productData.categories_ids,
      name: productData.name,
      price: productData.price,
      special: productData.special,
      quantity: productData.quantity,
      description: productData.description,
      weight: productData.weight,
      weightUnitLabel: productData.weight_unit_label,
      images: productData.images.map((imgE: any): ProductImage => ({
        thumb: imgE.thumb,
        full: imgE.full,
      })),
      ...(!productData.related ? {} : {
        related: productData.related.map((e: any) => this.mapProduct(e)),
      }),
    };
  }
}

export default ApiRepository;
