import { Currency } from '@/types/app';

const currencyFormatters: Record<Currency, Intl.NumberFormat> = {
  uzs: new Intl.NumberFormat('kk-KZ', {
    style: 'currency',
    currency: 'UZS',
    maximumFractionDigits: 0,
  }),
};

const formatCurrency = (value: number, currency: Currency = 'uzs')
  : string => currencyFormatters[currency].format(value);

export default {
  formatCurrency,
};
