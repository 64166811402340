<template>
  <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" class="app-icon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99992 1.5C4.59416 1.5 1.83325 4.26091
      1.83325 7.66667C1.83325 11.0724 4.59416 13.8333 7.99992 13.8333C11.4057 13.8333 14.1666
      11.0724 14.1666 7.66667C14.1666 4.26091 11.4057 1.5 7.99992 1.5ZM0.833252 7.66667C0.833252
      3.70863 4.04188 0.5 7.99992 0.5C11.958 0.5 15.1666 3.70863 15.1666 7.66667C15.1666
      11.6247 11.958 14.8333 7.99992 14.8333C4.04188 14.8333 0.833252 11.6247 0.833252 7.66667Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 4.5C8.27614 4.5 8.5 4.72386 8.5
      5V7.66667C8.5 7.94281 8.27614 8.16667 8 8.16667C7.72386 8.16667 7.5 7.94281
      7.5 7.66667V5C7.5 4.72386 7.72386 4.5 8 4.5Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.25 10.6667C7.25 10.2525 7.58579
      9.91669 8 9.91669H8.00667C8.42088 9.91669 8.75667 10.2525 8.75667 10.6667C8.75667
      11.0809 8.42088 11.4167 8.00667 11.4167H8C7.58579 11.4167 7.25 11.0809 7.25 10.6667Z"/>
  </svg>
</template>
