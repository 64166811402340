<template>
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" class="app-icon">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 5C1.75 4.58579 2.08579 4.25 2.5
      4.25H17.5C17.9142 4.25 18.25 4.58579 18.25 5C18.25 5.41421 17.9142 5.75 17.5
      5.75H2.5C2.08579 5.75 1.75 5.41421 1.75 5Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33342 2.41669C8.0903 2.41669
      7.85714 2.51326 7.68523 2.68517C7.51333 2.85708 7.41675 3.09024 7.41675
      3.33335V5.00002C7.41675 5.41423 7.08096 5.75002 6.66675 5.75002C6.25253 5.75002
      5.91675 5.41423 5.91675 5.00002V3.33335C5.91675 2.69241 6.17136 2.07772 6.62457
      1.62451C7.07779 1.1713 7.69248 0.916687 8.33342 0.916687H11.6667C12.3077 0.916687
      12.9224 1.1713 13.3756 1.62451C13.8288 2.07772 14.0834 2.69241 14.0834
      3.33335V5.00002C14.0834 5.41423 13.7476 5.75002 13.3334 5.75002C12.9192 5.75002
      12.5834 5.41423 12.5834 5.00002V3.33335C12.5834 3.09024 12.4868 2.85708 12.3149
      2.68517C12.143 2.51326 11.9099 2.41669 11.6667 2.41669H8.33342ZM4.16675 4.25002C4.58096
      4.25002 4.91675 4.58581 4.91675 5.00002V16.6667C4.91675 16.9098 5.01333 17.143 5.18523
      17.3149C5.35714 17.4868 5.5903 17.5834 5.83341 17.5834H14.1667C14.4099 17.5834 14.643
      17.4868 14.8149 17.3149C14.9868 17.143 15.0834 16.9098 15.0834 16.6667V5.00002C15.0834
      4.58581 15.4192 4.25002 15.8334 4.25002C16.2476 4.25002 16.5834 4.58581 16.5834
      5.00002V16.6667C16.5834 17.3076 16.3288 17.9223 15.8756 18.3755C15.4224 18.8287
      14.8077 19.0834 14.1667 19.0834H5.83341C5.19247 19.0834 4.57779 18.8287 4.12457
      18.3755C3.67136 17.9223 3.41675 17.3076 3.41675 16.6667V5.00002C3.41675 4.58581 3.75253
      4.25002 4.16675 4.25002Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.33325 8.41669C8.74747 8.41669 9.08325
      8.75247 9.08325 9.16669V14.1667C9.08325 14.5809 8.74747 14.9167 8.33325 14.9167C7.91904
      14.9167 7.58325 14.5809 7.58325 14.1667V9.16669C7.58325 8.75247 7.91904 8.41669 8.33325
      8.41669Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6667 8.41669C12.081 8.41669 12.4167
      8.75247 12.4167 9.16669V14.1667C12.4167 14.5809 12.081 14.9167 11.6667 14.9167C11.2525
      14.9167 10.9167 14.5809 10.9167 14.1667V9.16669C10.9167 8.75247 11.2525 8.41669 11.6667
      8.41669Z"/>
  </svg>
</template>
