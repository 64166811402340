import { defineStore } from 'pinia';
import { Order } from '@/types/app';
import ApiRepository from '@/repositories/api';

const useOrdersStore = defineStore({
  id: 'orders',
  state: () => ({
    orders: [] as Order[],
    isLoadingOrders: false,
    isOrdersLoaded: false,
  }),
  actions: {
    async loadOrders() {
      this.isOrdersLoaded = false;
      this.isLoadingOrders = true;
      const response = await ApiRepository.fetchOrders();
      this.orders = response.data;
      this.isLoadingOrders = false;
      this.isOrdersLoaded = true;
    },
  },
  getters: {
    getOrder(state): (orderId: number) => undefined|Order {
      return (orderId: number) => state.orders
        .find((e: Order) => e.orderId === orderId);
    },
  },
});

export default useOrdersStore;
