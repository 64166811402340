<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue';
import OrderCard from '@/components/OrderCard.vue';
import useOrdersStore from '@/store/orders';
import AppLoader from '@/components/AppLoader.vue';
import { onMounted } from 'vue';
// eslint-disable-next-line import/no-cycle
import { useTelegramWebApp } from '@/services/telegram-web-app';
import AppBtn from '@/components/AppBtn.vue';

const telegramWebApp = useTelegramWebApp();
const ordersStore = useOrdersStore();

if (!ordersStore.isOrdersLoaded && !ordersStore.isLoadingOrders) {
  ordersStore.loadOrders();
}

onMounted(() => {
  telegramWebApp.hideMainBtn();
});
</script>

<template>
  <div class="orders-page">
    <app-header :with-search="false" :title="$t('orderHistory')"/>
    <div class="orders-page__content">
      <div class="container">
        <div v-if="ordersStore.isLoadingOrders" class="orders-page__loader">
          <app-loader/>
        </div>
        <div v-else-if="ordersStore.orders.length === 0" class="orders-page__empty">
          {{ $t('orderIsEmpty') }}
          <app-btn @click.prevent="$router.push('/')"
                   class="orders-page__empty-btn">{{ $t('goShopping') }}</app-btn>
        </div>
        <div v-else class="orders-list">
          <order-card
            v-for="order in ordersStore.orders"
            :key="order.orderId"
            :order="order"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.orders-page {
  &__loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  &__content {
    padding: 20px 0;
  }
  &__empty {
    padding: 20px 0 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-btn {
      margin-top: 20px;
      width: 100%;
    }
  }
}
.orders-list {
  .order-card {
    & + .order-card {
      margin-top: 18px;
    }
  }
}
</style>
