import { defineStore } from 'pinia';
import { AppConfig } from '@/types/app';
import ApiRepository from '@/repositories/api';

const useAppConfigStore = defineStore({
  id: 'app-config',
  state: () => ({
    config: {} as AppConfig,
    isLoading: false,
    isLoaded: false,
  }),
  actions: {
    async loadConfig() {
      this.isLoaded = false;
      this.isLoading = true;
      const response = await ApiRepository.fetchTwaConfig();
      this.config = response.data;
      this.isLoading = false;
      this.isLoaded = true;
    },
  },
  getters: {
    //
  },
});

export default useAppConfigStore;
