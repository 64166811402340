import { defineStore } from 'pinia';
import { ShippingMethod } from '@/types/app';
import ApiRepository from '@/repositories/api';

const useShippingMethodsStore = defineStore({
  id: 'shopping-methods',
  state: () => ({
    methods: [] as ShippingMethod[],
    isLoading: false,
    isLoaded: false,
  }),
  actions: {
    async loadMethods() {
      this.isLoaded = false;
      this.isLoading = true;
      const response = await ApiRepository.fetchShippingMethods();
      this.methods = response.data;
      this.isLoading = false;
      this.isLoaded = true;
    },
  },
  getters: {
    //
  },
});

export default useShippingMethodsStore;
