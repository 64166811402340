<template>
  <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.38477 5.7959V6.56836C9.38477 6.66862 9.37109 6.7347 9.34375 6.7666C9.31641
        6.79395 9.25033 6.80762 9.14551 6.80762H6.82129V9.13184C6.82129 9.2321 6.80762
        9.29818 6.78027 9.33008C6.75293 9.35742 6.68685 9.37109 6.58203
        9.37109H5.78223C5.68197 9.37109 5.61589 9.35742 5.58398 9.33008C5.55664 9.29818
        5.54297 9.2321 5.54297 9.13184V6.80762H3.23926C3.13444 6.80762 3.06836 6.79395
        3.04102 6.7666C3.01367 6.73926 3 6.67318 3 6.56836V5.7959C3 5.69108 3.01367
        5.62728 3.04102 5.60449C3.06836 5.57715 3.13444 5.56348 3.23926
        5.56348H5.54297V3.22559C5.54297 3.12077 5.55436 3.05697 5.57715 3.03418C5.60449
        3.01139 5.67285 3 5.78223 3H6.58203C6.69141 3 6.75749 3.01139 6.78027
        3.03418C6.80762 3.05697 6.82129 3.12077 6.82129 3.22559V5.56348H9.14551C9.25033
        5.56348 9.31641 5.57715 9.34375 5.60449C9.37109 5.62728 9.38477 5.69108 9.38477 5.7959Z"
    />
  </svg>
</template>
