<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue';
import AppBtn from '@/components/AppBtn.vue';
import { useRoute, useRouter } from 'vue-router';
import useOrdersStore from '@/store/orders';
import moment from 'moment';
import helpers from '@/helpers';
import AppLoader from '@/components/AppLoader.vue';
import { onMounted, ref } from 'vue';
// eslint-disable-next-line import/no-cycle
import { useTelegramWebApp } from '@/services/telegram-web-app';
import IconMapPin from '@/icons/icon-map-pin.vue';
import useShoppingCartStore from '@/store/shopping-cart';
import useProductsStore from '@/store/products';
import { Order, OrderProduct, ShippingCartProduct } from '@/types/app';
import { useI18n } from 'vue-i18n';

const shoppingCart = useShoppingCartStore();
const telegramWebApp = useTelegramWebApp();
const route = useRoute();
const router = useRouter();
const orderId = parseInt(route.params.orderId as string, 10);
const ordersStore = useOrdersStore();
const productsStore = useProductsStore();
const order = ref<Order>();
const i18n = useI18n();

if (!ordersStore.isOrdersLoaded && !ordersStore.isLoadingOrders) {
  ordersStore.loadOrders();
}

const isRepeatLoading = ref(false);
const shippingCost = ref<number|null>(null);
const repeatOrder = async () => {
  isRepeatLoading.value = true;
  await productsStore.loadProducts();
  const products: ShippingCartProduct[] = order.value!.products
    .filter((e: OrderProduct) => (productsStore
      .getProduct(e.productId)?.quantity ?? 0) >= e.quantity)
    .map((e) => ({
      product: productsStore.getProduct(e.productId)!,
      quantity: e.quantity,
    }));
  if (products.length !== order.value!.products.length) {
    isRepeatLoading.value = false;
    telegramWebApp.showAlert(i18n.t('errorQuantityRepeatOrder'));
    return;
  }
  await shoppingCart.clear();
  await shoppingCart.changeProductQuantity(products);
  router.push({ name: 'cart' });
};

const init = async () => {
  telegramWebApp.hideMainBtn();
  if (!ordersStore.isOrdersLoaded) {
    await ordersStore.loadOrders();
  }
  order.value = ordersStore.getOrder(orderId)!;
  shippingCost.value = order.value!.totals.find((e) => e.code === 'shipping')?.value ?? null;
};

onMounted(() => {
  init();
});
</script>

<template>
  <div class="order-page">
    <app-header :with-search="false" :title="`${$t('order')} №${orderId}`"/>
    <div v-if="!order" class="order-page__loader"><app-loader/></div>
    <div v-else class="order-page__content">
      <div class="container">
        <div class="order-page__section">
          <div class="heading heading--3 order-page__date order-page__section-title">
            {{ moment(order.dateAdded).format('LLL') }}
          </div>
        </div>
        <div class="order-page__section">
          <div class="heading heading--3 order-page__section-title">
            {{ $t('status') }}
          </div>
          <div class="checkout-confirm-page__info-item">
            <div class="checkout-confirm-page__info-item-label">{{ order.orderStatus }}</div>
          </div>
        </div>
        <div class="order-page__section">
          <div class="heading heading--3 order-page__section-title">
            {{ $t('productsAndQuantity') }}
          </div>
          <div
            v-for="orderProduct in order.products"
            :key="orderProduct.orderProductId"
            class="checkout-confirm-page__info-item"
          >
            <div class="checkout-confirm-page__info-item-label">{{ orderProduct.name }}</div>
            <div class="checkout-confirm-page__info-item-value">
              <span style="padding-right: 1em">x{{ orderProduct.quantity }}</span>
              {{ helpers.formatCurrency(orderProduct.price) }}
            </div>
          </div>
        </div>
        <div class="order-page__section">
          <div class="heading heading--3 order-page__section-title">
            {{ $t('shipping') }}
          </div>
          <div class="checkout-confirm-page__info-item">
            <div class="checkout-confirm-page__info-item-label">{{ order.shippingMethod }}</div>
            <div v-if="shippingCost !== null"
                 class="checkout-confirm-page__info-item-value"
            >
              {{ helpers.formatCurrency(shippingCost) }}
            </div>
          </div>
        </div>
        <div class="order-page__section">
          <div class="heading heading--3 order-page__section-title">
            {{ $t('total') }}
          </div>
          <div class="checkout-confirm-page__info-item">
            <div class="checkout-confirm-page__info-item-label">
              {{ helpers.formatCurrency(order.total) }}
            </div>
          </div>
        </div>
        <div v-if="order.shippingAddress" class="order-page__section">
          <div class="heading heading--3 order-page__section-title">
            {{ $t('shippingAddress') }}
          </div>
          <div class="checkout-confirm-page__info-item">
            <div class="checkout-confirm-page__info-item-label">
              <div class="iconed-text"><icon-map-pin/> {{ order.shippingAddress }}</div>
            </div>
          </div>
        </div>
        <div class="order-page__section">
          <div class="heading heading--3 order-page__section-title">
            {{ $t('payment') }}
          </div>
          <div class="checkout-confirm-page__info-item">
            <div class="checkout-confirm-page__info-item-label">{{ order.paymentMethod }}</div>
          </div>
        </div>
        <div class="order-page__section">
          <app-btn class="order-page__repeat-btn" @click.prevent="repeatOrder"
                   :is-loading="isRepeatLoading">
            {{ $t('repeatOrder') }}
          </app-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.order-page {
  &__loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  &__content {
    padding: 20px 0;
  }
  &__date {
    text-transform: uppercase;
    color: var(--color-grey);
  }
  &__section {
    &-title {
      margin-bottom: 8px;
    }
    & + & {
      margin-top: 24px;
    }
  }
  &__info-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__repeat-btn {
    width: 100%;
  }
}
</style>
