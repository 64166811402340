import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createTelegramWebApp } from '@/services/telegram-web-app';
import i18n from '@/locale';
import App from './App.vue';
import router from './router';
import './global.scss';

createApp(App)
  .use(router)
  .use(createPinia())
  .use(createTelegramWebApp())
  .use(i18n)
  .mount('#app');
