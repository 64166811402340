<script setup lang="ts">
import { computed, defineProps } from 'vue';
import IconWeight from '@/icons/icon-weight.vue';
import IconArrowLeft from '@/icons/icon-arrow-left.vue';
import { useRoute, useRouter } from 'vue-router';
import IconCross from '@/icons/icon-cross.vue';

const router = useRouter();
const route = useRoute();

const isMyOrdersPage = computed(() => route.path.startsWith('/orders'));

defineProps({
  withSearch: {
    type: Boolean,
    default: true,
  },
  withBackBtn: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
  },
  search: {
    type: String,
  },
});
</script>

<template>
  <header class="app-header">
    <div class="container">
      <div class="app-header__wrapper">
        <button
          type="button"
          v-if="withBackBtn"
          class="app-header__back-btn"
          @click.prevent="() => router.back()"
        >
          <icon-arrow-left/>
        </button>
        <router-link v-else :to="{ name: 'catalog' }">
          <img class="app-header__logo" src="@/assets/logo.png" alt="БАХТ">
        </router-link>
        <label v-if="withSearch" class="app-header__search-bar search-bar" for="search-bar-input">
          <input type="text"
                 id="search-bar-input"
                 :placeholder="$t('search')"
                 :value="search"
                 @input="$emit('update:search', $event.target.value)"
          />
          <button v-if="search" type="button" class="search-bar__clear"
                @click.prevent="$emit('update:search', '')"><icon-cross/></button>
        </label>
        <div v-else class="app-header__title">{{ title }}</div>
        <router-link
          :to="{ name: 'orders' }"
          :class="[
            'my-orders-btn',
            isMyOrdersPage ? 'my-orders-btn--active' : '',
          ]"
        ><icon-weight/></router-link>
      </div>
    </div>
  </header>
</template>

<style lang="scss">
.my-orders-btn {
  background-color: var(--color-light-grey);
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all 0.3s;
  svg {
    fill: currentColor;
    width: 16px;
    height: 16px;
  }
  @at-root {
    .theme-light {
      .my-orders-btn {
        background-color: var(--color-light-grey);
        color: var(--color-black);
      }
    }
    .theme-dark {
      .my-orders-btn {
        background-color: var(--color-dark-grey);
        color: var(--color-white);
      }
    }
    .theme-light, .theme-dark {
      .my-orders-btn {
        &:hover {
          background-color: var(--color-grey);
        }
        &--active {
          background-color: var(--color-primary);
          color: #fff;
        }
      }
    }
  }
}

.search-bar{
  border-radius: 20px;
  height: 32px;
  background-color: var(--color-field-background);
  display: flex;
  position: relative;
  input {
    padding: 0px 38px 0 16px;
    border: none;
    outline: none;
    background: none;
    width: 100%;
    font-size: 14px;
    color: var(--color-text);
  }
  &__clear {
    background: none;
    border: none;
    position: absolute;
    right: 5px;
    top: 50%;
    line-height: 0;
    padding: 0.7em;
    transform: translateY(-50%);
    cursor: pointer;
    svg {
      width: 1.1em;
      height: 1.1em;
      fill: var(--color-grey);
    }
  }
}

.app-header {
  padding: 12px 0;
  &__search-bar {
    margin: 0 24px;
  }
  &__logo {
    width: 55px;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }
  &__title {
    font-size: 16px;
    font-family: Bastion, sans-serif;
    font-weight: 600;
    text-align: center;
  }
  &__back-btn {
    font-size: 24px;
    color: var(--color-black);
    background: transparent;
    border: none;
    padding: 0.17em;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s;
    border-radius: 50%;
    svg {
      width: 1em;
      height: 1em;
      fill: currentColor;
    }
    &:hover {
      background: var(--color-grey);
    }
  }
}

</style>
