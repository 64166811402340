<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { routeHistory } from '@/router';
import { useTelegramWebApp } from '@/services/telegram-web-app';
import useShoppingCartStore from '@/store/shopping-cart';
import AppLoader from '@/components/AppLoader.vue';
import useAppConfigStore from '@/store/app-config';
import useProfileStore from '@/store/profile';
import { useI18n } from 'vue-i18n';
import ApiRepository from '@/repositories/api';
import { Locale } from '@/types/app';
import AppTopAlert from '@/components/AppTopAlert.vue';

const route = useRoute();
const telegramWebApp = useTelegramWebApp();
const appConfigStore = useAppConfigStore();
const shoppingCartStore = useShoppingCartStore();
const profileStore = useProfileStore();
const i18n = useI18n({ useScope: 'global' });

const isInited = ref(false);

const updateTheme = () => {
  const items = document.querySelectorAll('.theme-light, .theme-dark');
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < items.length; i++) {
    items[i].classList.remove('theme-light', 'theme-dark');
    items[i].classList.add(`theme-${telegramWebApp.colorScheme}`);
  }
  const surfaceColor = getComputedStyle(document.documentElement).getPropertyValue('--color-surface');
  telegramWebApp.headerColor = surfaceColor;
  telegramWebApp.backgroundColor = surfaceColor;
};

const init = async () => {
  document.body.classList.add(`theme-${telegramWebApp.colorScheme}`);
  document.documentElement.classList.add(`theme-${telegramWebApp.colorScheme}`);
  updateTheme();
  i18n.locale.value = i18n.availableLocales.indexOf(telegramWebApp.languageCode || '') !== -1
    ? telegramWebApp.languageCode as string
    : i18n.fallbackLocale.value as string;
  ApiRepository.locale = i18n.locale.value as Locale;
  await ApiRepository.twaAuth(telegramWebApp.getInitData());
  await appConfigStore.loadConfig();
  profileStore.loadProfile();
  telegramWebApp.expand();
  telegramWebApp.onThemeChanged(updateTheme);
  isInited.value = true;
  await shoppingCartStore.load();
};

onMounted(() => init());

watch(route, (to, from) => {
  if (routeHistory.state.back) {
    telegramWebApp.showBackBtn();
  } else {
    telegramWebApp.hideBackBtn();
  }
});
</script>

<template>
  <app-top-alert/>
  <div v-if="!isInited" class="app-main-loader">
    <app-loader/>
  </div>
  <router-view v-else/>
</template>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app-main-loader {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: var(--tg-viewport-height);
}
</style>
