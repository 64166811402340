<script setup lang="ts">
import useShoppingCartStore from '@/store/shopping-cart';
import { onMounted, watch } from 'vue';
import { useTelegramWebApp } from '@/services/telegram-web-app';
import { useRouter } from 'vue-router';
import AppBtn from '@/components/AppBtn.vue';
import { useI18n } from 'vue-i18n';
import { onUnmounted } from '@vue/runtime-core';

const shoppingCartStore = useShoppingCartStore();
const telegramWebApp = useTelegramWebApp();
const router = useRouter();
const i18n = useI18n();

const onMainBtnClick = () => {
  router.push({ name: 'cart' });
};

const showMainBtn = () => {
  telegramWebApp.showMainBtn(
    `${i18n.t('goToOrder')} - ${shoppingCartStore.totalQuantity}`,
    onMainBtnClick,
  );
};

watch(() => shoppingCartStore.totalQuantity, () => {
  if (shoppingCartStore.totalQuantity > 0) {
    showMainBtn();
  } else {
    telegramWebApp.hideMainBtn();
  }
});
const showBtn = !telegramWebApp.isMainBtnAvailable();

onMounted(() => {
  if (shoppingCartStore.totalQuantity > 0) {
    showMainBtn();
  }
});
onUnmounted(() => telegramWebApp.removeMainBtnHandler(onMainBtnClick));
</script>

<template>
  <div v-if="showBtn" class="container">
    <app-btn>{{ $t('goToOrder') }}</app-btn>
  </div>
</template>
