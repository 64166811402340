<template>
  <div class="app-icon-wrapper">
    <svg viewBox="0 0 13 14" xmlns="http://www.w3.org/2000/svg" class="app-icon">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 1.5C5.45387 1.5 4.44077 1.95296
      3.6866 2.7757C2.93108 3.5999 2.5 4.72637 2.5 5.90909C2.5 7.63306 3.52506 9.28903
      4.62752 10.5585C5.17087 11.1842 5.71561 11.6961 6.12478 12.0515C6.26925 12.177
      6.39638 12.2826 6.5 12.3665C6.60362 12.2826 6.73075 12.177 6.87522 12.0515C7.28439
      11.6961 7.82913 11.1842 8.37248 10.5585C9.47494 9.28903 10.5 7.63306 10.5 5.90909C10.5
      4.72637 10.0689 3.5999 9.3134 2.7757C8.55923 1.95296 7.54613 1.5 6.5 1.5ZM6.5 13C6.20591
      13.4044 6.20579 13.4043 6.20564 13.4042L6.20527 13.4039L6.20421 13.4031L6.20088
      13.4007L6.18942 13.3922L6.14805 13.3612C6.11254 13.3344 6.06152 13.2953 5.9972
      13.2447C5.86862 13.1434 5.68661 12.9955 5.46897 12.8064C5.03439 12.4289 4.45413 11.884
      3.87248 11.2142C2.72494 9.89279 1.5 8.00331 1.5 5.90909C1.5 4.48787 2.01713 3.11705
      2.94944 2.09998C3.88309 1.08145 5.15918 0.5 6.5 0.5C7.84082 0.5 9.11691 1.08145
      10.0506 2.09998C10.9829 3.11705 11.5 4.48787 11.5 5.90909C11.5 8.00331 10.2751
      9.89279 9.12752 11.2142C8.54587 11.884 7.96561 12.4289 7.53103 12.8064C7.31339 12.9955
      7.13138 13.1434 7.0028 13.2447C6.93848 13.2953 6.88746 13.3344 6.85195 13.3612L6.81058
      13.3922L6.79912 13.4007L6.79579 13.4031L6.79473 13.4039L6.79436 13.4042C6.79421 13.4043
      6.79409 13.4044 6.5 13ZM6.5 13L6.79409 13.4044C6.61876 13.5319 6.38124 13.5319 6.20591
      13.4044L6.5 13Z"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 4.5C5.84024 4.5 5.375 4.98464
      5.375 5.5C5.375 6.01536 5.84024 6.5 6.5 6.5C7.15976 6.5 7.625 6.01536 7.625 5.5C7.625
      4.98464 7.15976 4.5 6.5 4.5ZM4.375 5.5C4.375 4.3585 5.36483 3.5 6.5 3.5C7.63517
      3.5 8.625 4.3585 8.625 5.5C8.625 6.6415 7.63517 7.5 6.5 7.5C5.36483 7.5 4.375
      6.6415 4.375 5.5Z"/>
    </svg>
  </div>
</template>
