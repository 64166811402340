<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import ProductsList from '@/components/ProductsList.vue';
import { Product } from '@/types/app';
import { useRoute, useRouter } from 'vue-router';
import useProductsStore from '@/store/products';
import AppLoader from '@/components/AppLoader.vue';
import helpers from '@/helpers';
import IconCross from '@/icons/icon-cross.vue';
import QuantityPanelV2 from '@/components/QuantityPanelV2.vue';
import AppBtn from '@/components/AppBtn.vue';
import useShoppingCartStore from '@/store/shopping-cart';

const productsStore = useProductsStore();
const shippingCartStore = useShoppingCartStore();
const router = useRouter();
const route = useRoute();
const productId = parseInt(route.params.productId as string, 10);
const product = ref<Product>();
const isLoadingProducts = ref(true);

const initProduct = async () => {
  isLoadingProducts.value = true;
  if (!productsStore.isProductsLoaded) {
    await productsStore.loadProducts();
  }
  isLoadingProducts.value = false;
  product.value = productsStore.getProduct(productId);
};

const inCartQuantity = computed<number>(() => (!product.value
  ? 0 : shippingCartStore.productQuantity(product.value!.productId)));

onMounted(() => {
  initProduct();
});
</script>

<template>
  <div class="product-page">
    <div class="container">
      <div v-if="isLoadingProducts">
        <app-loader/>
      </div>
      <div v-else-if="!product">{{ $t('errorProductNotFound') }}</div>
      <template v-else>
        <div class="product-page__image">
          <img :alt="product.name" :src="product.images[0].full"/>
          <button class="product-page__close-btn" @click.prevent="router.back()">
            <icon-cross/>
          </button>
        </div>
        <div class="product-page__content">
          <div class="product-page__description" v-html="product.description"/>
        </div>
        <div v-if="product.related && product.related.length > 0" class="product-page__section">
          <div class="product-page__section-title heading heading--3">
            {{ $t('buyWithThisProduct') }}
          </div>
          <products-list :products="product.related"/>
        </div>
      </template>
    </div>
    <div v-if="product" class="product-page__bottom">
      <div class="container">
        <div class="product-page__row">
          <div class="product-page__name">{{ product!.name }}</div>
          <div class="product-page__prices">
            <div class="product-page__price product-page__price--actual">
              {{ helpers.formatCurrency(product.special || product.price) }}
            </div>
            <div v-if="product.special"
                 class="product-page__price product-page__price--old"
            >{{ helpers.formatCurrency(product.price) }}</div>
          </div>
        </div>
        <div class="product-page__weight">{{ product.weight }}{{ product.weightUnitLabel }}</div>
        <div class="product-page__btns">
          <app-btn
            v-if="product.quantity === 0"
            disabled
            class="product-page__not-in-stock-btn"
          >{{ $t('notInStock') }}</app-btn>
          <template v-else>
            <quantity-panel-v2
              v-if="inCartQuantity > 0"
              class="product-page__quantity-panel"
              :model-value="inCartQuantity"
              :max="product.quantity"
              @update:model-value="quantity => shippingCartStore
                .changeProductQuantity([{ product: product!, quantity }])"
            />
            <app-btn
              v-if="inCartQuantity > 0"
              class="product-page__add-more-btn"
              @click.prevent="router.push({ name: 'catalog' })"
            >{{ $t('toAddMoreProduct') }}</app-btn>
            <app-btn
              v-else
              class="product-page__add-to-cart-btn"
              @click.prevent="shippingCartStore.addProduct(product)"
            >{{ $t('toAddToCart') }}</app-btn>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.product-page {
  &__btns {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-top: 8px;
  }
  &__quantity-panel {
    margin-right: 8px;
    max-width: 120px;
    flex-shrink: 0;
  }
  &__add-more-btn, &__add-to-cart-btn, &__not-in-stock-btn {
    white-space: nowrap;
    width: 100%;
  }
  &__bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: var(--color-surface);
    padding: 16px 0 20px;
    box-shadow: var(--color-surface-shadow);
  }
  &__row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__image {
    line-height: 0;
    position: relative;
    img {
      border-radius: 16px;
      max-width: 100%;
    }
  }
  &__close-btn {
    position: absolute;
    right: 8px;
    top: 8px;
    background: rgba(255, 255, 255, 0.5);
    border: none;
    font-size: 20px;
    padding: 0.4em;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s;
    svg {
      width: 1em;
      height: 1em;
      fill: var(--color-black);
    }
    &:hover {
      background: #fff;
    }
  }
  &__section, &__content {
    margin-top: 14px;
  }
  &__description {
    color: var(--color-dark-grey);
    p {
      margin: 0;
      & + p {
        margin-top: 8px;
      }
    }
  }
  &__name {
    width: 48%;
    font-size: 16px;
    font-weight: 600;
  }
  &__prices {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 48%;
  }
  &__price {
    &--actual {
      font-family: Bastion, sans-serif;
      font-size: 16px;
    }
    &--old {
      color: var(--color-primary);
      text-decoration: line-through;
      margin-left: 8px;
    }
  }
  &__weight {
    color: var(--color-dark-grey);
  }
}
</style>
