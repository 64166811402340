<script setup lang="ts">
import { computed, defineProps, PropType } from 'vue';
import { Product, ShippingCartProduct } from '@/types/app';
import helpers from '@/helpers';
import AppBtn from '@/components/AppBtn.vue';
import IconTrash from '@/icons/icon-trash.vue';
import QuantityPanelV2 from '@/components/QuantityPanelV2.vue';
import useShoppingCartStore from '@/store/shopping-cart';
import useProductsStore from '@/store/products';

const shoppingCartStore = useShoppingCartStore();
const productsStore = useProductsStore();

const props = defineProps({
  cartProduct: {
    type: Object as PropType<ShippingCartProduct>,
    required: true,
  },
});

const product = computed<Product>(() => props.cartProduct?.product);
const inStockQuantity = computed(() => productsStore.getProductQuantity(product.value.productId));
const inStock = computed(() => inStockQuantity.value > 0);
</script>

<template>
  <div class="cart-card">
    <div class="cart-card__left-content">
      <div class="cart-card__info">
        <div class="cart-card__name">{{ product.name }}</div>
        <div class="cart-card__weight">600gr.</div>
        <div class="cart-card__prices">
          <div class="cart-card__price cart-card__price--actual">
            {{ helpers.formatCurrency(product.special || product.price) }}
          </div>
          <div v-if="product.special"
               class="cart-card__price cart-card__price--old"
          >{{ helpers.formatCurrency(product.price) }}</div>
        </div>
        <div
          :class="`cart-card__stock ${inStockQuantity < 1 ? 'cart-card__stock--not-in-stock' : ''}`"
        >
          {{
            inStock
              ? $t('inStock', { quantity: inStockQuantity })
              : $t('notInStock')
          }}
        </div>
      </div>
      <div class="cart-card__quantity-controller">
        <app-btn
          size="sm"
          :class="`cart-card__remove-btn ${inStock ? '' : 'cart-card__remove-btn--full'}`"
          @click.prevent="() => shoppingCartStore.removeProduct(product)"
        >
          <template #icon-left><icon-trash/></template>
        </app-btn>
        <quantity-panel-v2
          v-if="inStock"
          :model-value="cartProduct.quantity"
          :max="cartProduct.product.quantity"
          @update:model-value="(quantity) => shoppingCartStore
            .changeProductQuantity([{ product, quantity }])"
        />
      </div>
    </div>
    <div class="cart-card__right-content">
      <div class="cart-card__image"><img :src="product.images[0].thumb" :alt="product.name"></div>
    </div>
  </div>
</template>

<style lang="scss">
.cart-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  &__stock {
    &--not-in-stock {
      color: var(--color-danger);
    }
  }
  &__left-content {
    width: 47%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__right-content {
    width: 45%;
  }
  &__image {
    line-height: 0;
    img {
      max-width: 100%;
      border-radius: 12px;
    }
  }
  &__prices {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  &__price {
    &--actual {
      font-family: Bastion, sans-serif;
      font-size: 16px;
    }
    &--old {
      color: var(--color-primary);
      text-decoration: line-through;
      margin-left: 8px;
    }
  }
  &__quantity-controller {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    .quantity-panel-v2 {
      border-radius: 12px;
      margin-left: 8px;
    }
  }
  &__remove-btn {
    flex-shrink: 0;
    &--full {
      width: 100%;
    }
  }
}
</style>
