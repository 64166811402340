<template>
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="app-icon">
    <path d="M12.7725 1.5285C12.5154 1.50952 12.2578 1.50001 12 1.5V0C12.2948 0.000143741
      12.5895 0.0111512 12.8835 0.033L12.7725 1.5285ZM15.7785 2.2035C15.2977 2.01796 14.8038
      1.86805 14.301 1.755L14.6295 0.291C15.204 0.42 15.7695 0.591 16.3185 0.804L15.7785
      2.2035V2.2035ZM17.8335 3.2685C17.619 3.12547 17.3994 2.99038 17.175 2.8635L17.9145
      1.5585C18.4274 1.8491 18.9182 2.17699 19.383 2.5395L18.4605 3.723C18.2571 3.56435 18.048
      3.41324 17.8335 3.27V3.2685ZM20.5845 5.9535C20.2878 5.53196 19.9604 5.13288 19.605
      4.7595L20.691 3.7245C21.096 4.152 21.471 4.6095 21.8115 5.0895L20.5845
      5.9535V5.9535ZM21.7005 7.9815C21.602 7.74369 21.495 7.50953 21.3795 7.2795L22.719
      6.6045C22.9839 7.13113 23.2095 7.67661 23.394 8.2365L21.969 8.706C21.8884 8.4613 21.7988
      8.21964 21.7005 7.9815V7.9815ZM22.4955 11.742C22.4834 11.2265 22.4332 10.7126 22.3455
      10.2045L23.823 9.9495C23.9235 10.5285 23.982 11.1165 23.997 11.7045L22.497
      11.742H22.4955ZM22.299 14.049C22.3485 13.794 22.389 13.5405 22.4205
      13.284L23.91 13.4685C23.838 14.0537 23.7226 14.6328 23.565 15.201L22.119 14.8005C22.188
      14.553 22.248 14.3025 22.299 14.049V14.049ZM20.871 17.6175C21.147 17.1825 21.39 16.7265
      21.6 16.2555L22.971 16.863C22.731 17.403 22.4535 17.922 22.1385 18.42L20.871 17.6175ZM19.425
      19.425C19.608 19.242 19.7835 19.053 19.95 18.858L21.087 19.8375C20.8945 20.0608 20.6938
      20.2769 20.4855 20.4855L19.425 19.425V19.425Z"/>
    <path d="M12.0008 1.5C10.2741 1.50012 8.5741 1.92607 7.05135 2.74011C5.5286 3.55415 4.2301
      4.73115 3.27087 6.16687C2.31163 7.6026 1.72127 9.25272 1.55207 10.9711C1.38287 12.6895
      1.64006 14.423 2.30084 16.0183C2.96163 17.6135 4.00563 19.0212 5.34037 20.1166C6.67511
      21.212 8.25939 21.9613 9.95288 22.2982C11.6464 22.6351 13.3968 22.5492 15.0492
      22.048C16.7015 21.5468 18.2048 20.6459 19.4258 19.425L20.4863 20.4855C19.0909 21.8817
      17.3727 22.9121 15.4838 23.4855C13.595 24.0589 11.5939 24.1575 9.65788 23.7727C7.72182
      23.3879 5.91057 22.5315 4.3846 21.2793C2.85864 20.0272 1.66508 18.418 0.909681
      16.5943C0.15428 14.7707 -0.139641 12.7888 0.0539606 10.8244C0.247562 8.85999 0.922709
      6.97365 2.01958 5.33253C3.11645 3.6914 4.60118 2.34617 6.34222 1.416C8.08326 0.485843
      10.0268 -0.000523098 12.0008 4.22187e-07V1.5Z"/>
    <path d="M11.25 4.5C11.4489 4.5 11.6397 4.57902 11.7803 4.71967C11.921 4.86032 12 5.05109
      12 5.25V13.065L16.872 15.849C17.0397 15.9502 17.1612 16.1129 17.2104 16.3024C17.2597 16.492
      17.2329 16.6933 17.1358 16.8633C17.0386 17.0333 16.8788 17.1586 16.6905 17.2124C16.5022
      17.2661 16.3003 17.2441 16.128 17.151L10.878 14.151C10.7632 14.0854 10.6678 13.9907 10.6014
      13.8764C10.535 13.762 10.5 13.6322 10.5 13.5V5.25C10.5 5.05109 10.579 4.86032 10.7197
      4.71967C10.8603 4.57902 11.0511 4.5 11.25 4.5V4.5Z"/>
  </svg>
</template>
