import { defineStore } from 'pinia';
import { Profile } from '@/types/app';
import ApiRepository from '@/repositories/api';

const useProfileStore = defineStore({
  id: 'profile',
  state: () => ({
    profile: {} as Profile,
    isLoading: false,
    isLoaded: false,
  }),
  actions: {
    async loadProfile() {
      this.isLoaded = false;
      this.isLoading = true;
      const response = await ApiRepository.fetchProfile();
      this.profile = response.data;
      this.isLoading = false;
      this.isLoaded = true;
    },
  },
  getters: {
    //
  },
});

export default useProfileStore;
