import { Locale } from '@/types/app';
import { createI18n } from 'vue-i18n';
import messages from '@/locale/messages';

const getMessagesForLocale = (locale: Locale) => {
  const localeMessages: any = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, message] of Object.entries(messages)) {
    localeMessages[key] = message[locale];
  }
  return localeMessages;
};

export default createI18n({
  legacy: false,
  fallbackLocale: 'ru-ru',
  availableLocales: ['ru-ru', 'uz-uz'] as Locale[],
  messages: {
    'ru-ru': getMessagesForLocale('ru-ru'),
    'uz-uz': getMessagesForLocale('uz-uz'),
  },
  pluralizationRules: {
    'ru-ru': function (choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2;
      }
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return (choicesLength < 4) ? 2 : 3;
    },
  },
});
