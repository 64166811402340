<script setup lang="ts">
import {
  defineEmits,
  provide,
  ref,
  defineProps,
  PropType,
  computed,
} from 'vue';

const emits = defineEmits([
  'update:modelValue',
]);

const props = defineProps({
  modelValue: {
    type: Object as PropType<number>,
  },
});
const localValue = ref<number>(props.modelValue || 0);

const container = ref();

const lastTabIndex = ref(-1);

const onTabClick = (index: number) => {
  localValue.value = index;
  emits('update:modelValue', localValue.value);
};

const onTabBeforeMount = (): number => {
  lastTabIndex.value += 1;
  return lastTabIndex.value;
};

provide('onTabClick', onTabClick);
provide('onTabBeforeMount', onTabBeforeMount);
provide('currentActiveTabIndex', computed(() => localValue.value));
</script>

<template>
  <div class="app-tabs" ref="container">
    <slot/>
  </div>
</template>

<style lang="scss">
.app-tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow: auto;
  padding-bottom: 6px;
}
</style>
