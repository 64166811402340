import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import CatalogPage from '@/views/pages/CatalogPage.vue';
import ProductPage from '@/views/pages/ProductPage.vue';
import CartPage from '@/views/pages/CartPage.vue';
import CheckoutPage from '@/views/pages/CheckoutPage.vue';
import CheckoutConfirmPage from '@/views/pages/CheckoutConfirmPage.vue';
import OrdersPage from '@/views/pages/OrdersPage.vue';
import OrderPage from '@/views/pages/OrderPage.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'catalog',
    component: CatalogPage,
    children: [
      {
        name: 'product',
        path: 'product/:productId',
        component: ProductPage,
      },
    ],
  },
  {
    path: '/cart',
    name: 'cart',
    component: CartPage,
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutPage,
  },
  {
    path: '/checkout/confirm',
    name: 'checkout-confirm',
    component: CheckoutConfirmPage,
  },
  {
    path: '/orders',
    name: 'orders',
    component: OrdersPage,
  },
  {
    path: '/orders/:orderId',
    name: 'order',
    component: OrderPage,
  },
];

export const routeHistory = createWebHistory();
const router = createRouter({
  history: routeHistory,
  routes,
});

export default router;
