import { defineStore } from 'pinia';

type TopAlertType = 'error' | 'warning';

const useTopAlertsStore = defineStore({
  id: 'top-alert',
  state: () => ({
    message: null as string | null,
    type: 'error' as TopAlertType,
    showTimeout: null as number | null,
  }),
  actions: {
    showErrorAlert(message: string) {
      this.showAlert(message, 'error');
    },
    showWarningAlert(message: string) {
      this.showAlert(message, 'warning');
    },
    showAlert(message: string, type: TopAlertType) {
      if (this.$state.showTimeout) {
        clearTimeout(this.$state.showTimeout);
      }
      this.$state.type = type;
      this.$state.message = message;
      this.$state.showTimeout = setTimeout(this.hideAlert, 4000);
    },
    hideAlert() {
      this.$state.message = null;
      if (this.$state.showTimeout) {
        clearTimeout(this.$state.showTimeout);
        this.$state.showTimeout = null;
      }
    },
  },
  getters: {
    //
  },
});

export default useTopAlertsStore;
