<script setup lang="ts">
import IconCross from '@/icons/icon-cross.vue';
import useTopAlertsStore from '@/store/top-alerts';
import { computed, watch } from 'vue';

const topAlertStore = useTopAlertsStore();
const isShow = computed(() => topAlertStore.message);
const alertType = computed(() => topAlertStore.type);
const { hideAlert } = topAlertStore;
</script>

<template>
  <div class="app-top-alert-container">
    <div
      v-if="isShow"
      :class="[
        'app-top-alert',
        `app-top-alert--${alertType}`,
      ]"
    >
      <div class="app-top-alert__content">{{ topAlertStore.message }}</div>
      <button type="button" class="app-top-alert__close-btn"
              @click.prevent="hideAlert"><icon-cross/></button>
    </div>
  </div>
</template>

<style lang="scss">
.app-top-alert-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.app-top-alert {
  color: var(--color-white);
  padding: 16px;
  position: relative;
  &--error {
    background: var(--color-danger-dark);
  }
  &--warning {
    background: var(--color-warning-dark);
  }
  &__content {
    padding-right: 45px;
  }
  &__close-btn {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.1em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-white);
    border: none;
    padding: 0.4em;
    color: var(--color-black);
    border-radius: 50%;
    .app-icon {
      width: 1em;
      height: 1em;
      fill: currentColor;
    }
  }
}
</style>
