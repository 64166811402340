import { defineStore } from 'pinia';
import { Order, Product, ShippingCartProduct } from '@/types/app';
import ApiRepository from '@/repositories/api';

const useShoppingCartStore = defineStore({
  id: 'shopping-cart',
  state: () => ({
    products: [] as ShippingCartProduct[],
    isLoadingCart: false,
    isCartLoaded: false,
  }),
  actions: {
    async load() {
      this.isCartLoaded = false;
      this.isLoadingCart = true;
      const response = await ApiRepository.fetchCart();
      this.products = response.data;
      this.isLoadingCart = false;
      this.isCartLoaded = true;
    },
    async clear() {
      await ApiRepository.clearCart();
    },
    async addProduct(product: Product) {
      await this.changeProductQuantity([{ product, quantity: 1 }]);
    },
    async removeProduct(product: Product) {
      await this.changeProductQuantity([{ product, quantity: 0 }]);
    },
    async changeProductQuantity(cartProducts: ShippingCartProduct[]) {
      cartProducts.forEach((cartProduct) => {
        const foundIndex = this.$state.products
          .findIndex((e) => e.product.productId === cartProduct.product.productId);
        if (foundIndex === -1 && cartProduct.quantity > 0) {
          this.$state.products.push(cartProduct);
        } else if (cartProduct.quantity <= 0) {
          this.$state.products = this.$state.products
            .filter((e) => e.product.productId !== cartProduct.product.productId);
        } else {
          this.$state.products[foundIndex].quantity = cartProduct.quantity;
        }
      });
      await ApiRepository.changeCartProduct(
        cartProducts.map((e) => ({
          productId: e.product.productId,
          quantity: e.quantity,
        })),
      );
    },
  },
  getters: {
    totalQuantity(state): number {
      return state.products.reduce((prev, e) => prev + e.quantity, 0);
    },
    totalCost(state): number {
      return state.products.reduce((prev, e) => prev + (e.product.price * e.quantity), 0);
    },
    productQuantity(state): (productId: number) => number {
      return (productId: number): number => state.products
        .find((e: ShippingCartProduct) => e.product.productId === productId)?.quantity || 0;
    },
  },
});

export default useShoppingCartStore;
