<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue';
// eslint-disable-next-line import/no-cycle
import { useTelegramWebApp } from '@/services/telegram-web-app';
import { computed, onMounted, ref } from 'vue';
import IconMapPin from '@/icons/icon-map-pin.vue';
import AppAlert from '@/components/AppAlert.vue';
import IconWarning from '@/icons/icon-warning.vue';
import useCheckoutStore from '@/store/checkout';
import { Coupon, Order } from '@/types/app';
import api from '@/repositories/api';
import { useI18n } from 'vue-i18n';
import useAppConfigStore from '@/store/app-config';
import { onUnmounted } from '@vue/runtime-core';
import helpers from '../../helpers';

const checkoutStore = useCheckoutStore();
const appConfigStore = useAppConfigStore();
const order = computed<Order>(() => checkoutStore.order!);
const coupon = computed<Coupon|undefined>(() => checkoutStore.coupon);
const telegramWebApp = useTelegramWebApp();
const orderPayUrl = ref<string>();
const i18n = useI18n();
const shippingMethodAddress = computed(() => (checkoutStore.order!.shippingMethodCode === 'pickup'
  ? appConfigStore.config.store.addresses[0].address
  : checkoutStore.order!.shippingAddress));
const shippingCost: number|null = checkoutStore.order!.shippingMethodCode === 'pickup' ? null : order.value.totals.find((e) => e.code === 'shipping')?.value || 0;

const payOrder = async () => {
  telegramWebApp.showMainBtnProgress();
  if (!orderPayUrl.value) {
    const response = await api.payOrderViaTelegram(order.value.orderId);
    orderPayUrl.value = response.data?.url;
  }
  if (!orderPayUrl.value) {
    telegramWebApp.close();
    return;
  }
  telegramWebApp.openInvoice(orderPayUrl.value, (status) => {
    /**
     * @ts-ignore */
    if (status !== 'pending') {
      telegramWebApp.hideMainBtnProgress();
    }
    /**
     * @ts-ignore */
    if (status === 'paid') {
      telegramWebApp.close();
      /**
       * @ts-ignore */
    } else if (status === 'failed') {
      telegramWebApp.showAlert(i18n.t('errorPaymentTryAgain'));
    }
  });
};

onMounted(() => {
  telegramWebApp.showMainBtn(i18n.t('toConfirmOrder'), payOrder);
});

onUnmounted(() => {
  telegramWebApp.removeMainBtnHandler(payOrder);
});
</script>

<template>
  <div class="checkout-confirm-page">
    <app-header :title="$t('yourOrder')" :with-search="false"/>
    <div class="checkout-confirm-page__content">
      <div class="container">
        <div class="checkout-confirm-page__section">
          <div class="heading heading--3 checkout-confirm-page__section-title">
            {{ $t('orderProducts') }}
          </div>
          <div
            v-for="orderProduct in order.products"
            :key="orderProduct.orderProductId"
            class="checkout-confirm-page__info-item"
          >
            <div class="checkout-confirm-page__info-item-label">{{ orderProduct.name }}</div>
            <div class="checkout-confirm-page__info-item-value">
              <span style="padding-right: 1em">x{{ orderProduct.quantity }}</span>
              {{ helpers.formatCurrency(orderProduct.price) }}
            </div>
          </div>
        </div>
        <div class="checkout-confirm-page__section">
          <div class="heading heading--3 checkout-confirm-page__section-title">
            {{ order.shippingMethod }}
          </div>
          <div class="checkout-confirm-page__info-item">
            <div class="checkout-confirm-page__info-item-label">
              <div class="iconed-text"><icon-map-pin/> {{ shippingMethodAddress }}</div>
            </div>
            <div v-if="shippingCost !== null" class="checkout-confirm-page__info-item-value">
              {{ helpers.formatCurrency(shippingCost) }}
            </div>
          </div>
        </div>
        <div v-if="coupon" class="checkout-confirm-page__section">
          <div class="heading heading--3 checkout-confirm-page__section-title">
            {{ $t('promocode') }}
          </div>
          <div class="checkout-confirm-page__info-item">
            <div class="checkout-confirm-page__info-item-label">{{ coupon.code }}</div>
            <div class="checkout-confirm-page__info-item-value">
              {{ coupon.type === "P"
                ? `-${coupon.discount}%`
                : `-${helpers.formatCurrency(coupon.discount)}` }}
            </div>
          </div>
        </div>
        <div class="checkout-confirm-page__section">
          <div class="heading heading--3 checkout-confirm-page__section-title">
            {{ $t('total') }}
          </div>
          <div class="checkout-confirm-page__info-item">
            <div class="checkout-confirm-page__info-item-label">
              {{ helpers.formatCurrency(order.total) }}
            </div>
          </div>
          <app-alert class="checkout-confirm-page__alert">
            {{ $t('priceCanBeChange') }}
            <template #icon><icon-warning/></template>
          </app-alert>
        </div>
        <div class="checkout-confirm-page__section">
          <div class="heading heading--3 checkout-confirm-page__section-title">
            {{ $t('payment') }}
          </div>
          <div class="checkout-confirm-page__info-item">
            <div class="checkout-confirm-page__info-item-label">{{ order.paymentMethod }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.checkout-confirm-page {
  &__content {
    padding: 20px 0;
  }
  &__section {
    &-title {
      margin-bottom: 8px;
    }
    & + & {
      margin-top: 24px;
    }
  }
  &__info-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__alert {
    margin-top: 8px;
  }
}
</style>
