<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue';
// eslint-disable-next-line import/no-cycle
import { useTelegramWebApp } from '@/services/telegram-web-app';
import { onMounted, ref, watch } from 'vue';
import router from '@/router';
import AppRadio from '@/components/AppRadio.vue';
import AppTextField from '@/components/AppTextField.vue';
import useShippingMethodsStore from '@/store/shipping-methods';
import usePaymentMethodsStore from '@/store/payment-methods';
import { phoneMask } from '@/app.config';
import AppLoader from '@/components/AppLoader.vue';
import {
  Coupon,
  Order,
  OrderCreateStockErrorDataItem,
  PaymentMethod,
  ShippingMethod,
} from '@/types/app';
import IconMapPin from '@/icons/icon-map-pin.vue';
import useAppConfigStore from '@/store/app-config';
import ApiRepository from '@/repositories/api';
import useCheckoutStore from '@/store/checkout';
import useProfileStore from '@/store/profile';
import { useI18n } from 'vue-i18n';
import { onUnmounted } from '@vue/runtime-core';
import useTopAlertsStore from '@/store/top-alerts';
import helpers from '@/helpers';
import { ResponseErrorTypes } from '@/types/api/common';
import AppAlert from '@/components/AppAlert.vue';

const topAlertStore = useTopAlertsStore();
const telegramWebApp = useTelegramWebApp();
const shippingMethodsStore = useShippingMethodsStore();
const paymentMethodsStore = usePaymentMethodsStore();
const appConfigStore = useAppConfigStore();
const checkoutStore = useCheckoutStore();
const profileStore = useProfileStore();
const i18n = useI18n();

const selectedShippingMethod = ref<ShippingMethod>();
const selectedPaymentMethod = ref<PaymentMethod>();
const customerName = ref<string>('');
const shippingAddress = ref<string>('');
const customerPhone = ref<string>('');
const coupon = ref<string>('');
const setProfileDataToFields = () => {
  customerName.value = `${profileStore.profile.firstname} ${profileStore.profile.lastname}`.trim();
  shippingAddress.value = profileStore.profile?.addresses?.[0]?.address || '';
  customerPhone.value = `+${profileStore.profile.telephone.trim().replaceAll(/\D/g, '')}`;
};
const createError = ref<string>();
const errorSectionEl = ref();

if (profileStore.isLoaded) {
  setProfileDataToFields();
} else {
  watch(profileStore, () => (profileStore.isLoaded ? setProfileDataToFields() : null));
}

const validateForm = (): boolean => {
  if (!selectedShippingMethod.value) {
    topAlertStore.showWarningAlert(i18n.t('errorChooseShippingMethod'));
    // telegramWebApp.showAlert(i18n.t('errorChooseShippingMethod'));
    return false;
  }
  console.log(shippingAddress.value, "  -  ",shippingAddress.value.length );
  if ((selectedShippingMethod.value?.needAddress ?? false) && shippingAddress.value.length === 0) {
    topAlertStore.showWarningAlert(i18n.t('errorChooseShippingAddress'));
    return false;
  }
  if (customerName.value.length === 0) {
    topAlertStore.showWarningAlert(i18n.t('errorEnterYourName'));
    return false;
  }
  if (customerPhone.value.length === 0) {
    topAlertStore.showWarningAlert(i18n.t('errorEnterYourPhone'));
    return false;
  }
  if (!selectedPaymentMethod.value) {
    topAlertStore.showWarningAlert(i18n.t('errorChoosePaymentMethod'));
    return false;
  }
  return true;
};

const onOrderBtnClick = async () => {
  if (!validateForm()) return;
  createError.value = undefined;
  telegramWebApp.showMainBtnProgress();
  document.querySelector('input:focus, textarea:focus')?.blur();
  let couponInfo: undefined|Coupon;
  if (coupon.value) {
    couponInfo = (await ApiRepository.checkCoupon(coupon.value))?.data;
    if (!couponInfo) {
      telegramWebApp.showAlert(i18n.t('errorCouponInvalidOrActivatedOrExpired'));
      telegramWebApp.hideMainBtnProgress();
      return;
    }
  }
  const response = await ApiRepository.createOrder({
    recipient: {
      phone: customerPhone.value,
      name: customerName.value,
    },
    paymentMethod: selectedPaymentMethod.value!.code,
    shippingMethod: selectedShippingMethod.value!.code,
    address: selectedShippingMethod.value!.needAddress ? shippingAddress.value : undefined,
    coupon: couponInfo?.code,
  });
  if (response?.error) {
    if (response?.error === ResponseErrorTypes.SOME_PRODUCT_NOT_IN_STOCK) {
      let msg = `${i18n.t('errorOrderNotInStock')}\n\n`;
      msg += (response?.data as OrderCreateStockErrorDataItem[])
        .map((e: OrderCreateStockErrorDataItem) => i18n.t('errorOrderNotInStockProduct', {
          productName: e.name,
          cartQuantity: e.cartQuantity,
          productQuantity: e.productQuantity,
        }))
        .join('\n\n');
      createError.value = msg.replaceAll('\n', '<br>');
      setTimeout(() => errorSectionEl.value.scrollIntoView({ behavior: 'smooth' }), 200);
      telegramWebApp.hideMainBtnProgress();
      return;
    }
    if (response?.error === ResponseErrorTypes.ERR_MIN_ORDER_AMOUNT) {
      let msg = `${i18n.$t('errorMinOrderAmount', {
              min_order_amount: helpers.formatCurrency( appConfigStore.config.min_order_amount ),
              })}\n\n`;
      createError.value = msg.replaceAll('\n', '<br>');
      setTimeout(() => errorSectionEl.value.scrollIntoView({ behavior: 'smooth' }), 200);
      telegramWebApp.hideMainBtnProgress();
      return;
    }
    telegramWebApp.hideMainBtnProgress();
    return;
  }
  const order = response?.data as Order;
  checkoutStore.changeOrder(order);
  if (couponInfo) {
    checkoutStore.changeCoupon(couponInfo);
  }
  telegramWebApp.hideMainBtnProgress();
  router.push({ name: 'checkout-confirm' });
};

onMounted(() => {
  telegramWebApp.showMainBtn(i18n.t('toConfirmOrder'), onOrderBtnClick);
});

onUnmounted(() => {
  telegramWebApp.removeMainBtnHandler(onOrderBtnClick);
});

watch(() => selectedShippingMethod.value?.code, (newCode) => {
  if (newCode === 'pickup') {
    shippingAddress.value = '';
  } else {
    shippingAddress.value = profileStore.profile?.addresses?.[0]?.address || '';
  }
}, { immediate: true });

</script>

<template>
  <div class="checkout-page">
    <app-header :title="$t('orderCheckout')" :with-search="false"/>
    <div v-if="!profileStore.isLoaded" class="checkout-page__loader"><app-loader/></div>
    <div v-else class="checkout-page__content">
      <div class="container">
        <div class="checkout-page__section">
          <div v-if="shippingMethodsStore.isLoading" class="checkout-page__section-loader">
            <app-loader/>
          </div>
          <div v-else class="checkout-page__shipping-methods">
            <div
              v-for="method in shippingMethodsStore.methods"
              :key="method.code"
              class="checkout-page__shipping-method"
            >
              <app-radio
                :model-value="selectedShippingMethod?.code === method.code"
                @update:model-value="(e: boolean) => e ? (selectedShippingMethod = method) : null"
              >
                <template #label><div class="heading heading--3">{{ method.name }}</div></template>
              </app-radio>
              <div class="checkout-page__shipping-method-cost">
                {{ method.cost === 0 ? method.code === 'pickup' ? $t('free') : ""  : `+ ${helpers.formatCurrency(method.cost)}` }}
              </div>
            </div>
          </div>
          <div v-if="selectedShippingMethod?.needAddress && selectedShippingMethod?.code !== 'pickup'" class="checkout-page__shipping-info">
            <div class="checkout-page__form-input">
              <app-text-field :label="$t('shippingAddress')"
                              :placeholder="$t('enterShippingAddress')"
                              v-model.trim="shippingAddress"/>
            </div>
          </div>
          <div v-if="selectedShippingMethod?.code === 'pickup'"
               class="checkout-page__shipping-info">
            <div
              v-for="address in appConfigStore.config.store.addresses"
              :key="address.address"
              class="checkout-page__shipping-pickup-address"
            >
              <app-radio
                :model-value="shippingAddress === address.address"
                @update:model-value="(e: boolean) => e ? (shippingAddress = address.address) : null"
              >
                <template #label><div class="iconed-text"><icon-map-pin/> {{ address.address }}</div></template>
              </app-radio>              
            </div>
          </div>
        </div>
        <div class="checkout-page__section">
          <div class="heading heading--3 checkout-page__section-title">
            {{ $t('contactsData') }}
          </div>
          <div class="checkout-page__form-input">
            <app-text-field
              :label="$t('fullNameShorted')"
              :placeholder="$t('enterFullNameShorted')" v-model.trim="customerName"/>
          </div>
          <div class="checkout-page__form-input">
            <app-text-field :label="$t('phoneNumber')" placeholder="+998" :mask="phoneMask"
                            v-model="customerPhone"/>
          </div>
        </div>
        <div class="checkout-page__section">
          <div class="heading heading--3 checkout-page__section-title">{{ $t('payment') }}</div>
          <div v-if="paymentMethodsStore.isLoading" class="checkout-page__section-loader">
            <app-loader/>
          </div>
          <div v-else class="checkout-page__payment-methods">
            <div
              v-for="method in paymentMethodsStore.methods"
              :key="method.code"
              class="checkout-page__payment-method"
            >
              <app-radio
                :label="method.name"
                :model-value="selectedPaymentMethod?.code === method.code"
                @update:model-value="(e: boolean) => e ? (selectedPaymentMethod = method) : null"
              />
            </div>
          </div>
        </div>
        <div class="checkout-page__section">
          <div class="heading heading--3 checkout-page__section-title">{{ $t('promocode') }}</div>
          <div class="checkout-page__form-input">
            <app-text-field :label="$t('enterPromocode')" :placeholder="$t('enterPromocode')"
                            v-model.trim="coupon"/>
          </div>
        </div>
        <div v-if="createError" class="checkout-page__section" ref="errorSectionEl">
          <app-alert type="error" circular>
            <templat v-html="createError"/>
          </app-alert>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.checkout-page {
  &__loader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }
  &__content {
    padding: 20px 0;
  }
  &__shipping-methods {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  &__shipping-method {
    &-cost {
      color: var(--color-dark-grey);
    }
    & + & {
      margin-left: 20px;
    }
  }
  &__shipping-info {
    margin-top: 16px;
  }
  &__section {
    &-title {
      margin-bottom: 8px;
    }
    & + & {
      margin-top: 24px;
    }
    &-loader {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 20px;
    }
  }
  &__form {
    &-input {
      & + & {
        margin-top: 10px;
      }
    }
  }
  &__payment-methods {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: -6px -10px;
  }
  &__payment-method {
    margin: 6px 10px;
  }
}
</style>
