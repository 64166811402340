import { Locale } from '@/types/app';

export default {
  toAdd: {
    'ru-ru': 'Добавить',
    'uz-uz': 'Qo\'shish',
  },
  search: {
    'ru-ru': 'Поиск',
    'uz-uz': 'Qidirish',
  },
  goToOrder: {
    'ru-ru': 'Перейти к заказу',
    'uz-uz': 'Buyurtmaga o‘tish',
  },
  emptyRequestResult: {
    'ru-ru': 'По Вашему запросу ничего не найдено',
    'uz-uz': 'Sizning so‘rovingiz bo‘yicha hech narsa topilmadi',
  },
  checkoutOrder: {
    'ru-ru': 'Оформить заказ',
    'uz-uz': 'Buyurtmani rasmiylashtirish',
  },
  cartIsEmpty: {
    'ru-ru': 'Ваша корзина пуста',
    'uz-uz': 'Savatingiz bo‘sh',
  },
  yourOrder: {
    'ru-ru': 'Ваш заказ',
    'uz-uz': 'Sizning buyurtmangiz',
  },
  all: {
    'ru-ru': 'Все',
    'uz-uz': 'Hammasi',
  },
  stocks: {
    'ru-ru': 'Акции',
    'uz-uz': 'Aksiyalar',
  },
  errorPaymentTryAgain: {
    'ru-ru': 'Ошибка платежа. Попробуйте снова',
    'uz-uz': 'To‘lov xatosi. Qayta urinib ko‘ring',
  },
  toPay: {
    'ru-ru': 'Оплатить',
    'uz-uz': 'To‘lash',
  },
  orderProducts: {
    'ru-ru': 'Позиции, что Вы заказали',
    'uz-uz': 'Siz buyurtma qilgan mahsulotlar',
  },
  promocode: {
    'ru-ru': 'Промокод',
    'uz-uz': 'Promokod',
  },
  enterPromocode: {
    'ru-ru': 'Введите промокод',
    'uz-uz': 'Promokodni kiriting',
  },
  total: {
    'ru-ru': 'Всего',
    'uz-uz': 'Jami',
  },
  priceCanBeChange: {
    'ru-ru': 'Цена может измениться после сбора заказа!',
    'uz-uz': 'Buyurtma olingandan keyin narx o‘zgarishi mumkin!',
  },
  payment: {
    'ru-ru': 'Оплата',
    'uz-uz': 'To‘lov',
  },
  errorCouponInvalidOrActivatedOrExpired: {
    'ru-ru': 'Купон введен неверно; был уже активирован или окончен срок его действия',
    'uz-uz': 'Kupon noto‘g‘ri kiritilgan; allaqachon faollashtirilgan yoki muddati tugagan',
  },
  toConfirmOrder: {
    'ru-ru': 'Подтвердить заказ',
    'uz-uz': 'Buyurtmani tasdiqlang',
  },
  orderCheckout: {
    'ru-ru': 'Подтвердить заказ',
    'uz-uz': 'Buyurtmani tasdiqlang',
  },
  free: {
    'ru-ru': 'Бесплатно',
    'uz-uz': 'Bepul',
  },
  shippingAddress: {
    'ru-ru': 'Адрес доставки',
    'uz-uz': 'Yetkazib berish manzili',
  },
  enterShippingAddress: {
    'ru-ru': 'Введите адрес доставки',
    'uz-uz': 'Yetkazib berish manzilini kiriting',
  },
  contactsData: {
    'ru-ru': 'Контактные данные',
    'uz-uz': 'Aloqa ma’lumotlarini',
  },
  fullNameShorted: {
    'ru-ru': 'ФИО',
    'uz-uz': 'FISh',
  },
  enterFullNameShorted: {
    'ru-ru': 'Введите ФИО',
    'uz-uz': 'FIShni kiriting',
  },
  phoneNumber: {
    'ru-ru': 'Номер телефона',
    'uz-uz': 'Telefon raqamingiz',
  },
  order: {
    'ru-ru': 'Заказ',
    'uz-uz': 'Buyurtma',
  },
  repeatOrder: {
    'ru-ru': 'Повторить заказ',
    'uz-uz': 'Buyurtmani takrorlash',
  },
  orderHistory: {
    'ru-ru': 'История заказов',
    'uz-uz': 'Buyurtmalar tarixi',
  },
  errorProductNotFound: {
    'ru-ru': 'Товар не найден',
    'uz-uz': 'Mahsulot topilmadi',
  },
  buyWithThisProduct: {
    'ru-ru': 'С этим товаром покупают',
    'uz-uz': 'Ushbu mahsulot bilan quyidagilarni xarid qilishadi',
  },
  toAddToCart: {
    'ru-ru': 'Добавить в корзину',
    'uz-uz': 'Savatga qo‘shish',
  },
  toAddMoreProduct: {
    'ru-ru': 'Добавить еще товар',
    'uz-uz': 'Boshqa mahsulot qo’shing',
  },
  errorChooseShippingMethod: {
    'ru-ru': 'Пожалуйста, выберите способ доставки',
    'uz-uz': 'Yetkazib berish usulini tanlang',
  },
  errorChooseShippingAddress: {
    'ru-ru': 'Пожалуйста, введите адрес доставки',
    'uz-uz': 'Yetkazib berish manzilini kiriting',
  },
  errorEnterYourName: {
    'ru-ru': 'Пожалуйста, введите Ваше имя',
    'uz-uz': 'Iltimos, ismingizni kiriting',
  },
  errorEnterYourPhone: {
    'ru-ru': 'Пожалуйста, введите Ваш номер телефона',
    'uz-uz': 'Iltimos, telefon raqamingizni kiriting',
  },
  errorChoosePaymentMethod: {
    'ru-ru': 'Пожалуйста, выберите способ оплаты',
    'uz-uz': 'To‘lov usulini tanlang',
  },
  orderIsEmpty: {
    'ru-ru': 'Вы пока не делали заказы у нас :(',
    'uz-uz': 'Siz hali bizga buyurtma bermadingiz :(',
  },
  goShopping: {
    'ru-ru': 'За покупками',
    'uz-uz': 'Xaridlar qilish',
  },
  status: {
    'ru-ru': 'Статус',
    'uz-uz': 'Holat',
  },
  shipping: {
    'ru-ru': 'Доставка',
    'uz-uz': 'Yetkazib berish',
  },
  productsAndQuantity: {
    'ru-ru': 'Позиции и кол-во',
    'uz-uz': 'Lavozim va miqdor',
  },
  errorQuantityRepeatOrder: {
    'ru-ru': 'Невозможно повторить заказ - некоторых позиций нет в наличии',
    'uz-uz': 'Buyurtmani takrorlashning iloji yo\'q - ba\'zi mahsulotlar stokda yo\'q',
  },
  notInStock: {
    'ru-ru': 'Нет в наличии',
    'uz-uz': 'Mavjud emas',
  },
  errorMinOrderAmount: {
    'ru-ru': 'Невозможно создать заказ. Минимальная сумма заказа {min_order_amount}',
    'uz-uz': 'Buyurtma yaratib bo‘lmadi. Minimal buyurtma miqdori {min_order_amount}',
  },
  errorOrderNotInStock: {
    'ru-ru': 'Невозможно создать заказ. Некоторых товаров уже нет в том наличии, в котором Вам требуется',
    'uz-uz': 'Buyurtma yaratib bo‘lmadi. Ba\'zi mahsulotlar siz talab qilganidek stokda yo\'q',
  },
  errorOrderNotInStockProduct: {
    'ru-ru': '{productName}\nВы заказываете: {cartQuantity}\nСейчас в наличии: {productQuantity}',
    'uz-uz': '{productName}\nSiz buyurtma qilasiz: {cartQuantity}\nHozirda stokda: {productQuantity}',
  },
  inStock: {
    'ru-ru': 'В наличии: {quantity}',
    'uz-uz': 'Stokda: {quantity}',
  },
} as Record<string, Record<Locale, string>>;
